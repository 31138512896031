import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/**** RUTA LANDINGS ****/
import { HomeComponent } from './home/home.component';
import { CondicionesGeneralesComponent } from './condiciones-generales/condiciones-generales.component';
import { PoliticasPrivacidadComponent } from './politicas-privacidad/politicas-privacidad.component';
import { AvisoLegalComponent } from './aviso-legal/aviso-legal.component';
import { ContactoComponent } from './contacto/contacto.component';
import { FaqsComponent } from './faqs/faqs.component';
import { NuestroEquipoComponent } from './nuestro-equipo/nuestro-equipo.component';
import { PricingComponent } from './pricing/pricing.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { TrabajaConNosotrosComponent } from './trabaja-con-nosotros/trabaja-con-nosotros.component';
import { PartnersComponent } from './partners/partners.component';
import { AgenciasComponent } from './agencias/agencias.component';
import { ProveedorComponent } from './proveedor/proveedor.component';
import { DialogElements } from './dialog-element/dialog-elements.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: '**',
    redirectTo: 'not-found'
  },
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'condiciones-generales',
    component: CondicionesGeneralesComponent
  },
  {
    path: 'politicas-privacidad',
    component: PoliticasPrivacidadComponent
  },
  {
    path: 'aviso-legal',
    component: AvisoLegalComponent
  },
  {
    path: 'contacto',
    component: ContactoComponent
  },
  {
    path: 'faqs',
    component: FaqsComponent
  },
  {
    path: 'nuestro-equipo',
    component: NuestroEquipoComponent
  },
  {
    path: 'quienes-somos',
    component: QuienesSomosComponent
  },
  {
    path: 'partners',
    component: PartnersComponent
  },
  {
    path: 'agencias',
    component: AgenciasComponent
  },

  {
    path: 'proveedor',
    component: ProveedorComponent
  },
  {
    path: 'trabaja-con-nosotros',
    component: TrabajaConNosotrosComponent
  },
  {
    path: 'modal',
    component: DialogElements
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
