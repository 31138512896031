<div class="row">
    <div class="col-sm-12">
        <div class="slider slider-pricing">
          <div class="content-slider container">
            <div class="content-slider-info">
              <h2>Mucho más que una marca blanca.<br>
				100% confirmación online.<br>
			</h2>
              <p>
				Un portal B2C para tus clientes, tu propia tienda online. Integrado con<br>
				uno de los más potentes sistemas de administración Online.
              </p>
			</div>
			<div class="content-slider-img">
				<img class="draw-images" src="/assets/images/ilustracion_pricing.svg" />
			</div>
          </div>
        </div> 
	</div>
</div> 
<div class="info-graficos pt-5">
	<img src="/assets/images/grafico_web-03.jpg" />
</div>





<div class="container my-5">

	                                                                   
	<div class="section-utilidades container mt-6 mb-6">
		<div class="title mb-5">
		 
		  <h4>un sistema versátil, modular, integral, eficiente y al costo más bajo del mercado</h4>
		  <h2>posibles usos de bazar</h2>
		</div>  

		<div class="row ">
		  <div class="col-md-4">
			  <img src="/assets/images/plataforma.png" />
			  <h4>plataforma</h4>
			  <p>producto propio, integrado y herramientas que facilitan la gestión integral del negocio. <br>tus propias reglas, tu modelo de distribución</p>
		  </div>
		  <div class="col-md-4">
			<img src="/assets/images/agregador.png" />
			<h4>agregador</h4>
			<p>si ya posees tu sistema, nuestra estructura modular complementa tanto a nivel sistema como producto tu solución</p>
		  </div>
		  <div class="col-md-4">
			  <img src="/assets/images/api.png" />
			  <h4>api</h4>
			  <p>tus propias reglas con tu equipo de IT asociado a nuestros expertos para lograr el Proyecto más personalizado possible.</p>
		  </div>
		</div>  
	</div>
	
		<div class="card-fullwidth-block">
			<div class="table-responsive">
				<table class="table pricing-table" border="0" cellspacing="0">
					<thead>
						<tr>
							<td></td>
							<td>
								<img class="img-logo mb-4 width-50 corregir-img-1" src="/assets/images/iconos/svg/onetravel_logo.svg" alt="One Travel"/>
								<p class="text-muted font-weight-normal">
									Ideal para tus agencias con su propia marca blanca B2C incluyendo la opción de cargar su propio producto.
								</p>
							</td>
							<td>
								<img class="img-logo mb-4 width-80 corregir-img-2" src="/assets/images/iconos/svg/travel_agency.svg" alt="Travel Agency"/>
								<p class="text-muted font-weight-normal">
									La última tecnología en viajes a medida multiservicio, multi-destino con integraciones online.
								</p>
							</td>
							<td style="display: none;">
								<img class="img-logo mb-4 width-80 corregir-img-3"  src="/assets/images/iconos/svg/inspiration_travel.svg" alt="Inspiration Travel"/>
								<p class="text-muted font-weight-normal">
									Tecnología e inspiración para viajar. Multiintegraciones con contenido relacional que permite crear experiencias de viajes inmersivas.
								</p>
							</td>
							<td>
								<img class="img-logo mb-4 width-80 corregir-img-4" src="/assets/images/iconos/svg/touroperador.svg" alt="Tour Operator"/>
								<p class="text-muted font-weight-normal">
									Producto para Tour Operadores que permite una eficaz gestión de clientes y reservas. Multiintegraciones y administración y distribución de Producto Propio. Set de herramientas de comunicación en Site Builder.
								</p>
							</td>
						</tr>

					</thead>
					<tbody>
						<tr>
							<td class=""></td>
							<td><span class="precio m-0"><a target="_blank" href="https://tiendadeturismo.com/OneTravel/_" class="button btn-blue mb-5">ver demo</a></span></td>
							<td><span class="precio m-0"><a target="_blank" href="https://tiendadeturismo.com/travelagency/_"  class="button btn-blue mb-5">ver demo</a></span></td>
							<td style="display: none;"><span class="precio m-0"><button class="button btn-blue mb-5">ver demo</button></span></td>
							<td><span class="precio m-0"><a target="_blank" href="https://tiendadeturismo.com/touroperator/_"  class="button btn-blue mb-5">ver demo</a></span></td>
						</tr>
						<tr style="display:none;" class="destacado">
							<td class="resaltar con-color">Precio</td>
							<td><span class="precio m-0"><sup>$</sup>000</span></td>
							<td><span class="precio m-0"><sup>$</sup>000</span></td>
							<td style="display: none;"><span class="precio m-0"><sup>$</sup>000</span></td>
							<td><span class="precio m-0"><sup>$</sup>000</span></td>
						</tr>
						<tr>
							<td class="resaltar">Agencias</td>
							<td class="detalle">1</td>
							<td class="detalle">5</td>
							<td  style="display: none;" class="detalle">Unlimited</td>
							<td class="detalle">Unlimited</td>
						</tr>
						<tr>
							<td class="resaltar">Límite</td>
							<td class="detalle">10 GB</td>
							<td class="detalle">50 GB</td>
							<td  style="display: none;" class="detalle">250 GB</td>
							<td class="detalle">Unlimited</td>
						</tr>
						<tr>
							<td class="resaltar">Usuarios</td>
							<td class="detalle">3</td>
							<td class="detalle">10</td>
							<td style="display: none;" class="detalle">50</td>
							<td class="detalle">Unlimited</td>
						</tr>
						<tr class="destacado">
							<td class="resaltar con-color">Características</td>
							<td></td>
							<td></td>
							<td style="display: none;"></td>
							<td></td>
						</tr>
						<tr>
							<td class="resaltar">Backup tools</td>
							<td><i class="material-icons text-success">check_circle</i></td>
							<td><i class="material-icons text-success">check_circle</i></td>
							<td style="display: none;"><i class="material-icons text-success">check_circle</i></td>
							<td><i class="material-icons text-success">check_circle</i></td>
						</tr>
						<tr>
							<td class="resaltar">Atención especializada</td>
							<td><i class="material-icons text-muted">minimize</i></td>
							<td><i class="material-icons text-muted">minimize</i></td>
							<td style="display: none;"><i class="material-icons text-success">check_circle</i></td>
							<td><i class="material-icons text-success">check_circle</i></td>
						</tr>
						<tr>
							<td class="resaltar"></td>
							<td><a routerlink="contacto" ng-reflect-router-link="contacto" href="/contacto" class="button btn-blue contactar">consultar</a></td>
							<td><a routerlink="contacto" ng-reflect-router-link="contacto" href="/contacto" class="button btn-blue contactar">consultar</a></td>
							<td style="display: none;"><button routerlink="contacto" class="button btn-blue contactar ml-4">consultar</button></td>
							<td><a routerlink="contacto" ng-reflect-router-link="contacto" href="/contacto" class="button btn-blue contactar">consultar</a></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div style="display:none" class="clientes-opinan mt-5 pt-5">
			<div class="title mb-5">
				<h2>nuestros clientes opinan sobre nuestros productos</h2>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 my-2"> 
					<div class="content-opinion">
						<div class="icono-opinion"> 
						<img src="/assets/images/foto_cliente.jpg" />
						</div>          
						<div class="texto-opinion">
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et tristique massa. Nunc pharetra blandit fringilla. Donec nec sapien justo. Pellentesque leo libero.
							</p>
							<h5>Long View</h5>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 my-2"> 
					<div class="content-opinion">
						<div class="icono-opinion"> 
						<img src="/assets/images/foto_cliente.jpg" />
						</div>          
						<div class="texto-opinion">
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et tristique massa. Nunc pharetra blandit fringilla. Donec nec sapien justo. Pellentesque leo libero.
							</p>
							<h5>Live Travel</h5>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 my-2"> 
					<div class="content-opinion">
						<div class="icono-opinion"> 
						<img src="/assets/images/foto_cliente.jpg" />
						</div>          
						<div class="texto-opinion">
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et tristique massa. Nunc pharetra blandit fringilla. Donec nec sapien justo. Pellentesque leo libero.
							</p>
							<h5>Destinos a medida</h5>
						</div>
					</div>
				</div>
			</div>	
		</div>

