import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { CondicionesGeneralesComponent } from './condiciones-generales/condiciones-generales.component';
import { PoliticasPrivacidadComponent } from './politicas-privacidad/politicas-privacidad.component';
import { AvisoLegalComponent } from './aviso-legal/aviso-legal.component';
import { ContactoComponent } from './contacto/contacto.component';
import { FaqsComponent } from './faqs/faqs.component';
import { NuestroEquipoComponent } from './nuestro-equipo/nuestro-equipo.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { TrabajaConNosotrosComponent } from './trabaja-con-nosotros/trabaja-con-nosotros.component';
import { FooterComponent } from './footer/footer.component';
import { PartnersComponent } from './partners/partners.component';
import { AgenciasComponent } from './agencias/agencias.component';
import { ProveedorComponent } from './proveedor/proveedor.component';
import { DialogElements } from './dialog-element/dialog-elements.component';

/* IMPORT MODULES VALIDATE FORM */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* IMPORT SLICK SLIDER */
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* IMPORT ANGULAR MATERIAL */
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { DialogModule } from 'primeng/dialog';


import { HttpClientModule } from '@angular/common/http';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
// import { environment } from 'src/environments/environment';
import { CarouselModule } from 'primeng/carousel';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PricingComponent } from './pricing/pricing.component';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: Hammer.DIRECTION_ALL,
    }
  };
}
/* End Custom hammer configuration */

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    PricingComponent,
    CondicionesGeneralesComponent,
    PoliticasPrivacidadComponent,
    AvisoLegalComponent,
    ContactoComponent,
    FaqsComponent,
    NuestroEquipoComponent,
    QuienesSomosComponent,
    TrabajaConNosotrosComponent,
    PartnersComponent,
    AgenciasComponent,
    ProveedorComponent,
    DialogElements
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    HttpClientModule,
    MatMenuModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    CarouselModule,
    NgxSkeletonLoaderModule,
    DialogModule
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    MatNativeDateModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
