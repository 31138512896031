import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { ServerService } from '../server.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  showTab = 0;
  news: UntypedFormGroup;
  button_text= "suscríbete";
  faltanCampos = 0;
  faltaEmail = 0;
  displayBasic: boolean;

  constructor(public fb: UntypedFormBuilder,private server: ServerService, private metaService: Meta) {
    this.news = this.fb.group({
      email:          ['', []]
    });
  }

  ngOnInit() {
    this.metaService.addTags([
      {name: 'description', content: 'Nacimos para motorizar el cambio en la industria del turismo. Contáctate con nosotros por más información: Sede Montevideo. Ruta 8, Km 17500. Montevideo, Zonamerica - Edificio Biotec Local 005'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }

  showBasicDialog() {
    this.displayBasic = true;
  }


  onSubmit() {
    console.log("saveData");
    if(this.formularioValido() == false){
      console.log("no");
      return;
    };
    console.log("si");
    this.button_text = "enviando...";
    this.news.value.asunto = "Suscripción newsletter";
    this.server.submitEmail(this.news.value).subscribe(val => console.log(val));
    this.button_text = "¡suscripto!";
    this.news.reset();
    this.showBasicDialog();
  }

  formularioValido() {
    console.log(this.news);
    this.faltanCampos = 0;
    if(this.news.value.email == "" ){   this.faltaEmail++;    this.faltanCampos++; }else{ this.faltaEmail = 0;    }

    if(this.faltanCampos > 0){    return false;   }
    return true;
  }

  tabToggle(index){
    if(index == this.showTab){
      this.showTab = 0 ;
    }
    else{
      this.showTab =index;
    }
    
  }
}
