import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-aviso-legal',
  templateUrl: './aviso-legal.component.html',
  styleUrls: ['./aviso-legal.component.scss']
})
export class AvisoLegalComponent implements OnInit {
  showTab = 0;
 

  constructor(private metaService: Meta) { }

  ngOnInit() {
    
     this.metaService.addTags([
      {name: 'description', content: 'la nueva plataforma de soluciones adaptables a todos los negocios del turismo potenciamos los resultados de tu empresa presentando y mejorando tu oferta de manera más atractiva, divertida y rápida'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }


  tabToggle(index){
    if(index == this.showTab){
      this.showTab = 0 ;
    }
    else{
      this.showTab =index;
    }
    
  }
}
