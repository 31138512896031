import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { ServerService } from '../server.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-trabaja-con-nosotros',
  templateUrl: './trabaja-con-nosotros.component.html',
  styleUrls: ['./trabaja-con-nosotros.component.scss']
})
export class TrabajaConNosotrosComponent implements OnInit {
  button_text= "enviar";
  showTab = 0;
  contacto: UntypedFormGroup;
  randnumber;
  extension;
  faltanCampos = 0;
  faltaNombre = 0;
  faltaEmail = 0;
  faltaTel = 0;
  faltaIdiomas = 0;
  faltaArea = 0;
  faltaFile = 0;
  faltaMensaje = 0;

  

  constructor(public fb: UntypedFormBuilder,private server: ServerService, private metaService: Meta) {
    this.contacto = this.fb.group({
      nombre:         ['', []],
      email:          ['', []],
      tel:            ['', []],
      idiomas:        ['', []],
      area:           ['', []],
      mensaje:        ['', []],
      file:           ['', []],
      fileSource:     ['', []],
      ubicacion:      ['', []],
      rand:           ['', []]
    });
  }

  saveData() {
    //Revisar: 
    //Que avise cuando envio o no el mail
    //Campos obligatorios
   
    if(this.formularioValido() == false){
      return;
    };

    this.button_text = "enviando...";

    this.randnumber = this.getRandomInt(9999999, 9999999999999);
    const formData = new FormData();
    formData.append('file', this.contacto.get('fileSource').value);
    formData.append('rand', this.randnumber);
    this.contacto.value.rand = this.randnumber;
  
    this.extension = this.contacto.get('fileSource').value;
  
    this.extension = this.extension.name.substring(this.extension.name.length-4);
    if( this.extension != ".pdf"){
      alert("El archivo adjunto debe ser PDF");
      return;
    }
  
    this.server.upload(formData);

    this.contacto.value.asunto = "RRHH: Bazar de Turismo";
    this.server.submitEmail(this.contacto.value).subscribe(val => console.log(val));
    console.log(this.contacto.value.file);
    this.button_text = "¡mensaje enviado!";
    this.contacto.reset();
  }

  ngOnInit() {

    this.metaService.addTags([
      {name: 'description', content: 'Bazar de turismo es una empresa joven e innovadora que ofrece una gama de productos para el turismo. Nuestro equipo trabaja coordinadamente con un nivel de compromiso profesional constante, promovemos el liderazgo, la innovación, creemos en el equilibrio entre trabajo y vida personal. Nuestra sede se encuentra en Uruguay y con dos centros de desarrollo, uno en Argentina y otro en el Caribe. Buscamos en forma constante nuevos talentos. Si consideras que puedes aportar a nuestra compañía, ser parte de este equipo, sumar  nuevas ideas y desarrollarte profesionalmente en nuestro proyecto, envíanos tu currículum usando el formulario en esta página.'},
      {name: 'robots', content: 'index, follow'}
    ]);

  }

  formularioValido() {
    this.faltanCampos = 0;
    if(this.contacto.value.nombre == "" ){  this.faltaNombre++;   this.faltanCampos++; }else{ this.faltaNombre = 0;   }
    if(this.contacto.value.email == "" ){   this.faltaEmail++;    this.faltanCampos++; }else{ this.faltaEmail = 0;    }
    if(this.contacto.value.tel == "" ){     this.faltaTel++;      this.faltanCampos++; }else{ this.faltaTel = 0;      }
    if(this.contacto.value.idiomas == "" ){ this.faltaIdiomas++;  this.faltanCampos++; }else{ this.faltaIdiomas = 0;  }
    if(this.contacto.value.area == "" ){    this.faltaArea++;     this.faltanCampos++; }else{ this.faltaArea = 0;     }
    if(this.contacto.value.file == "" ){    this.faltaFile++;     this.faltanCampos++; }else{ this.faltaFile = 0;     }
    if(this.contacto.value.mensaje == "" ){ this.faltaMensaje++;  this.faltanCampos++; }else{ this.faltaMensaje = 0;  } 
    if(this.faltanCampos > 0){    return false;   }
    return true;
  }

  onFileChange(event) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.contacto.patchValue({
        fileSource: file
      });
    }
  }
     
 
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }


  tabToggle(index){
    if(index == this.showTab){
      this.showTab = 0 ;
    }
    else{
      this.showTab =index;
    }
    
  }
}
