import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

    constructor(private http: HttpClient) {
    }

    private async request(method: string, url: string, data?: any) {


      const result = this.http.request(method, url, {
        body: data,
        responseType: 'json',
        observe: 'body',
        headers: {
          Authorization: `Bearer`
        }
      });
      return new Promise((resolve, reject) => {
        result.subscribe(resolve, reject);
      });
    }


    submitEmail(data) {

      return this.http.post('https://zeusgroup.net/submit.php',data);
    }


    upload(formData){
      
     
      this.http.post('https://zeusgroup.net/upload.php', formData)
        .subscribe(res => {
          console.log(res);

        })
    }


}
