import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-condiciones-generales',
  templateUrl: './condiciones-generales.component.html',
  styleUrls: ['./condiciones-generales.component.scss']
})
export class CondicionesGeneralesComponent implements OnInit {
  showTab = 0;
 

  constructor() { }

  ngOnInit() {
  }


  tabToggle(index){
    if(index == this.showTab){
      this.showTab = 0 ;
    }
    else{
      this.showTab =index;
    }
    
  }
}
