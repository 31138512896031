<div class="row">
  <div class="col-sm-12">
      <div class="slider trabaja-con-nosotros">
        <div class="content-slider container">
          <div class="content-slider-info">
            <h2>súmate a nuestro equipo</h2>
            <p>
              si quieres trabajar con nosotros<br>
              aportar nuevas ideas y desarrollarte profesionalmente<br>
              en nuestro proyecto, envíanos tu currículum,<br>
              rellenando el formulario en esta página.
            </p>
            <button routerLink="/quienes-somos" class="button btn-wihte">sobre nosotros</button><button routerLink="/pricing" class="button btn-blue ml-4">nuestras soluciones</button>
          </div>

          <div style="text-align: left;" class="content-slider-img">
            <img style="width: 190px;margin-left: 100px;" class="draw-images" src="/assets/images/draw_1.svg" />
          </div>
         
        </div>
      </div> 
  </div>   
</div>
<div class="container pt-4 mb-4">
  <div class="row container">
    
    <div class="col-sm-12 pt-5">
      
      
      <p class="mb-5">Bazar de turismo es una empresa joven e innovadora que ofrece una gama de productos para el turismo. Nuestro equipo trabaja coordinadamente con un nivel de compromiso profesional constante, promovemos el liderazgo, la innovación, creemos en el equilibrio entre trabajo y vida personal. Nuestra sede se encuentra en Uruguay y con dos centros de desarrollo, uno en Argentina y otro en el Caribe. Buscamos en forma constante nuevos talentos. Si consideras que puedes aportar a nuestra compañía, ser parte de este equipo, sumar  nuevas ideas y desarrollarte profesionalmente en nuestro proyecto, envíanos tu currículum usando el formulario en esta página.</p>
      
      <div class="title mb-5">
        <h2>por favor rellena el formulario si estas interesado</h2>
      </div>
      <form class="formulario-contacto curriculum" #formContact="ngForm" [formGroup]="contacto" (ngSubmit)="saveData()" novalidate>
        <div class="row">
          <div class="col-md-4">
            <input class="input" type="text" formControlName="nombre" [ngClass]="{'aviso-input': faltaNombre > 0 }" placeholder="nombre y apellido*" requerid>
          </div>
          <div class="col-md-4">
            <input class="input" type="text" formControlName="email" [ngClass]="{'aviso-input': faltaEmail > 0 }" placeholder="e-mail*">
          </div>
          <div class="col-md-4">
            <input class="input" type="text" formControlName="tel" [ngClass]="{'aviso-input': faltaTel > 0 }" placeholder="teléfono*">
          </div>
        </div>
        <div class="row mt-4 seccion-2">
          <div class="col-md-4">
            <input class="input" type="text" formControlName="idiomas" [ngClass]="{'aviso-input': faltaIdiomas > 0 }" placeholder="idiomas*">
          </div>
          <div class="col-md-4">
            <input class="input" type="text" formControlName="area" [ngClass]="{'aviso-input': faltaArea > 0 }" placeholder="área*">
          </div>
          <div class="col-md-4">
            <div class="w-100 position-relative">
              <input class="input w-100" type="text" placeholder="adjúntanos tu CV*" [ngClass]="{'aviso-input': faltaFile > 0 }" disabled>
              <a (click)="fileInput.click()" class="button adjuntar btn-blue ml-4 position-absolute" style="right:0;border: 1px solid #009bdb !important;">adjuntar</a>  
            </div>
          </div>
         
          <input 
              style="display:none;" 
              #fileInput 
              formControlName="file"
              id="file" 
              type="file" 
              class="form-control"
              accept=".pdf"
              (change)="onFileChange($event)"> 

          <div class="col-sm-12  mt-5">
            <textarea class="input textarea" formControlName="mensaje" [ngClass]="{'aviso-input': faltaMensaje > 0 }" rows="7" placeholder="Experiencia y formación*"></textarea>
          </div>
          <div [ngClass]="{'aviso-text': faltanCampos > 0 }" class="col-sm-6 pb-4 pl-3 ">
            *Campos obligatorios
          </div>
          <div class="col-sm-6 text-align-right pt-1">
            <button class="button btn-blue ml-4">{{button_text}}</button>   
          </div>
        </div>

      </form>

      <div style="display:none;" class="clientes-opinan mt-5 pt-5 mb-5">
        <div class="title mb-5">
          <h2>nuestros clientes opinan sobre nuestros productos</h2>
        </div>

        <div class="row">
          <div class="col-md-4"> 
            <div class="content-opinion">
              <div class="icono-opinion"> 
              <img src="/assets/images/foto_cliente.jpg" />
              </div>          
              <div class="texto-opinion">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et tristique massa. Nunc pharetra blandit fringilla. Donec nec sapien justo. Pellentesque leo libero.
                </p>
                <h5>Long View</h5>
              </div>
            </div>
          </div>
          <div class="col-md-4"> 
            <div class="content-opinion">
              <div class="icono-opinion"> 
              <img src="/assets/images/foto_cliente.jpg" />
              </div>          
              <div class="texto-opinion">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et tristique massa. Nunc pharetra blandit fringilla. Donec nec sapien justo. Pellentesque leo libero.
                </p>
                <h5>Live Travel</h5>
              </div>
            </div>
          </div>
          <div class="col-md-4"> 
            <div class="content-opinion">
              <div class="icono-opinion"> 
              <img src="/assets/images/foto_cliente.jpg" />
              </div>          
              <div class="texto-opinion">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et tristique massa. Nunc pharetra blandit fringilla. Donec nec sapien justo. Pellentesque leo libero.
                </p>
                <h5>Destinos a medida</h5>
              </div>
            </div>
          </div>
        </div>	
      </div>
    </div>
  </div>
</div>


