<div class="slider slider-quienes-somos">
  <div class="content-slider slider-quienes-somos container">
    <div class="content-slider-info">
      <h2>quienes somos</h2>
      <p>
        Somos expertos en Tecnología aplicada al Turismo <br>
        y desarrollamos este innovador modelo de negocio <br>
        que permitirá una presencia omnicanal, una fuente de<br>
        diferenciación en la nueva realidad, que permitirá crear<br>
        nuevas normas y jugadores en la industria de los viajes.
      </p>
      <button routerLink="/contacto" class="button btn-wihte">contáctanos</button><button routerLink="/pricing" class="button btn-blue ml-4">nuestras soluciones</button>
    </div>
    <div class="content-slider-img">
      <img class="draw-images" src="/assets/images/draw_5.svg" />
    </div>
   
  </div>
</div> 
<div class="container pt-4 texto-quienes-somos">
  <div class="row container">
    <div class="col-sm-12 pt-5">
      

      <div class="container mt-5 mb-5">
        <div class="title">
          <h2>un innovador modelo de negocio para  productos turísticos</h2>
        </div>
      
        <div class="row mt-5">
          <div class="col-md-6"> 
            <p><b>Somos una compañía especializada en el desarrollo de soluciones para la industria turística global.</b> Sus socios fundadores poseen más de 25 años de experiencia en el rubro junto a un equipo de profesionales que equilibran la amplia experiencia en la gestión de empresas turísticas y el uso de todos los recursos que la tecnología actual ofrece.</p>
            <p><b>Con clientes en Argentina, Chile, Colombia, Cuba, España, México, Panamá, Perú, República Dominicana, Uruguay y Venezuela.</b></p>
            <p>Pero de repente el mundo se detuvo y entramos en una nueva era de los viajes. <b>El desafío sin precedentes que se ha presentado ha cambiado la industria de viajes tal y como la conocemos.</b> Entonces decidimos motorizar nuestra compañía para gestar un cambio profundo en ella, desde el interior de nuestra compañía hacia la industria de los viajes. <b>Repensando y explorando las nuevas necesidades.</b></p>
            <p><b>Hoy los viajeros no son ni puramente online ni tampoco offline. Buscan la mejor experiencia que combine ambos. Se mueven en un mundo omnicanal.</b> Deben tener información coherente, en tiempo real, al alcance de su mano, viajes que sean una experiencia memorable y sin complicaciones, donde sea que estén y buscan empresas que puedan asistirlo en cualquier necesidad, generando la tan mentada confianza que permita construir peldaños para ayudarlos a viajar por el mundo.</p>
            <p>Nuestra tecnología de omnicanalidad facilita a las marcas de viajes a responder ante </p>
          </div>
          <div class="col-md-6"> 
            <p>la necesidad de estar ultra conectado y a las necesidades cambiantes de los clientes permitiendo que estén allí para atenderlos sin problemas no importa en qué canal o dispositivo estén interactuando.</p>
            <p>Nuestro desafío ha sido crear un nuevo concepto de tecnología al servicio de la industria para asegurar la mejor experiencia de usuario tanto en móvil o escritorio así como facilitar la experiencia del consultor lo que a través de un servicio personalizado le permitirá atender todas las necesidades de un cliente que requiere atención durante el viaje.</p>
            <p>Esto significa no sólo tener la <b>capacidad de buscar y reservar contenido, sino también brindar seguimiento y asistencia de las reservas a través de diferentes mecanismos.</b></p>
            <p><b>Buscamos mejorar la eficiencia de nuestros clientes ofreciendo soluciones innovadoras que automatizan los procesos de consulta, la contratación de productos, la gestión interna y las vías de atención al cliente adecuadas.</b></p>
            <p><b>Nuestro marketplace resulta de un alto valor estratégico,</b> al consolidar todos los servicios integrados en una plataforma, permitiendo a cualquier empresa disponibilizar su propio producto y enriquecer su oferta sin necesidad de contratos con cada proveedor. <b>Esto resulta en un innovador modelo de negocio para las nuevas normas y permite a todos nuestros clientes una excelente competitividad.</b></p>
          </div>
        </div>
        <div style="display:none;" class="title mt-5">
          <h2>un gran equipo de profesionales</h2>
        </div>


        <div  style="display:none;" class="section-quienes-somos-equipo row mt-5">


          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3"> 
            <img src="/assets/images/staff/victor.png" />
            <div class="descripcion">
              <h4>Victor Catania<br></h4>
              <p>
                Board Member<br><br>

                <a _ngcontent-iiq-c2="" class="social-links text-muted" href="https://www.linkedin.com/in/victor-catania-12560328/" target="_blank" title="Linkedin"><i _ngcontent-iiq-c2="" class="lab la-linkedin"></i></a>          
              </p>
            </div>
          </div>


          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3"> 
            <img src="/assets/images/staff/martin.png" />
            <div class="descripcion">
              <h4>Martín Invernizzi<br></h4>
              <p>
                CEO<br><br>
                <a _ngcontent-iiq-c2="" class="social-links text-muted" href="https://www.linkedin.com/in/martin-invernizzi-79566710/" target="_blank" title="Linkedin"><i _ngcontent-iiq-c2="" class="lab la-linkedin"></i></a>
                <a _ngcontent-iiq-c2="" class="social-links text-muted ml-1" href="mailto:martin@bazardeturismo.com" target="_blank" title="Email"><i _ngcontent-iiq-c2="" class="lar la-envelope"></i></a>
                
              </p>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3"> 
            <img src="/assets/images/staff/franco.png" />
            <div class="descripcion">
              <h4>Franco Catania<br></h4>
              <p>
                COO <br><br>
                <a _ngcontent-iiq-c2="" class="social-links text-muted" href="https://www.linkedin.com/in/franco-catania/" target="_blank" title="Linkedin"><i _ngcontent-iiq-c2="" class="lab la-linkedin"></i></a>
                <a _ngcontent-iiq-c2="" class="social-links text-muted ml-1" href="mailto:franco@bazardeturismo.com" target="_blank" title="Email"><i _ngcontent-iiq-c2="" class="lar la-envelope"></i></a>
              </p>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3"> 
            <img src="/assets/images/staff/facundo.png" />
            <div class="descripcion">
              <h4>Facundo Garcia Calabuig<br></h4>
              <p>
                CFO<br><br>
                <a _ngcontent-iiq-c2="" class="social-links text-muted" href="https://www.linkedin.com/in/facundo-garcia-calabuig-1bb29962/" target="_blank" title="Linkedin"><i _ngcontent-iiq-c2="" class="lab la-linkedin"></i></a>
              </p>
            </div>
          </div>






          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3"> 
              <img src="/assets/images/staff/humber.jpg" />
              <div class="descripcion">
                <h4>Humberto Almarza<br></h4>
                <p>
                  CTO
                  <br><br>
                  <a _ngcontent-iiq-c2="" class="social-links text-muted" href="https://www.linkedin.com/in/humberto-j-almarza-salas-aa7b6114/" target="_blank" title="Linkedin"><i _ngcontent-iiq-c2="" class="lab la-linkedin"></i></a>
                </p>
              </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3"> 
            <img src="/assets/images/staff/nico.jpg" />
            <div class="descripcion">
              <h4>Nicolás Luzzi<br></h4>
              <p>
                CIO 
                <br><br>

                <a _ngcontent-iiq-c2="" class="social-links text-muted" href="https://www.linkedin.com/in/nicolasluzzi/" target="_blank" title="Linkedin"><i _ngcontent-iiq-c2="" class="lab la-linkedin"></i></a>
              </p>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3"> 
          <img src="/assets/images/staff/pablo.jpg" />
          <div class="descripcion">
            <h4>Pablo Setien<br></h4>
            <p>
                CCO
                <br><br>
                <a _ngcontent-iiq-c2="" class="social-links text-muted" href="https://www.linkedin.com/in/pablo-setien/" target="_blank" title="Linkedin"><i _ngcontent-iiq-c2="" class="lab la-linkedin"></i></a>
                <a _ngcontent-iiq-c2="" class="social-links text-muted ml-1" href="mailto:pablo@bazardeturismo.com" target="_blank" title="Email"><i _ngcontent-iiq-c2="" class="lar la-envelope"></i></a>
              </p>
          </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3"> 
        <img src="/assets/images/vos.png" />
        <div class="descripcion">
          <h4>¿quieres trabajar con nosotros?</h4>
          <p>
            súmate desde <a class="color-principal" routerLink="/trabaja-con-nosotros">aquí</a>, te contactaremos en breve!
          </p>
        </div>
      </div>

      <button routerLink="/nuestro-equipo" class="button btn-blue mt-4 full-width">conoce a nuestro equipo</button>
    </div>
  </div> 
     

      <div class="section-home-newsletter container mb-6">
        <div class="title pt-5 mt-5 mb-5">
          <h2>contáctate con nosotros por más información</h2>
        </div>  
        <div class="row">
          <div class="col-md-4"> 
            <form (ngSubmit)="onSubmit()" #formNews="ngForm" [formGroup]="news">
              <input class="input" type="text" formControlName="email" name="email"  [ngClass]="{'aviso-input': faltaEmail > 0 }" placeholder="ingresa tu e-mail">
              <button class="button btn-blue ml-4 suscribite">{{button_text}}</button>   
            </form>
             <div [ngClass]="{'aviso-text': faltanCampos > 0 }" class="col-sm-6 pt-4 pl-3 position-absolute-25bt hidden">
                 debe ingresar su e-mail
             </div>
           </div>
          <div class="col-md-4">
            <button routerLink="/pricing" class="button btn-blue ml-4 full-width mb-2">ver soluciones</button>   
          </div>
          <div class="col-md-4">
            <button routerLink="/contacto" class="button btn-blue ml-4 full-width">contáctanos</button>   
          </div>
        </div> 
      </div>

      <p-dialog header="Titulo" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
        Muchas gracias por su interés en nuestra compañía, lo mantendremos informado de todas las novedades.
        <p-footer>
          <button type="button" pButton icon="pi pi-check" (click)="displayBasic=false" label="Yes">OK</button>
        </p-footer>
      </p-dialog>
     
    </div>
  </div>
</div>

