<div class="container">

    <button type="button" (click)="showBasicDialog()" pButton icon="pi pi-external-link" label="Show">test boton</button>

        <p-dialog header="Titulo" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
            probando contenido de modal
        <p-footer>
            <button type="button" pButton icon="pi pi-check" (click)="displayBasic=false" label="Yes">Cerrar</button>
        </p-footer>
    </p-dialog>

</div>
