import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { ServerService } from '../server.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-proveedor',
  templateUrl: './proveedor.component.html',
  styleUrls: ['./proveedor.component.scss']
})
export class ProveedorComponent implements OnInit {

  showTab = 0;
  showSegmentos = 0;
  showServicios = 0;
  contacto: UntypedFormGroup;
  paises: string[] = ['Ubicación *', 'Argentina', 'Bolivia', 'Brasil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 'Ecuador', 'El Salvador', 'Guayana Francesa', 'Granada', 'Guatemala', 'Guayana', 'Haití', 'Honduras', 'Jamaica', 'México', 'Nicaragua', 'Paraguay', 'Panamá', 'Perú', 'Puerto Rico', 'República Dominicana', 'Surinam', 'Uruguay', 'Venezuela'];
  default_pais: string = 'Ubicación *';

  segmentos: string[] = ['Idóneos', 'One Man Show', 'Red de Agencias', 'Franquicias', 'Tour Operators', 'DMC  ', 'Hoteles', 'Prestadores Terrestres', 'Gobiernos', 'Empresas de retail'];
 
  selectedSegmentos = [];

  servicios: string[] = ['Alojamientos', 'Autos', 'Vuelos', 'Circuitos', 'Traslados', 'Paquetes', 'Actividades', 'Asistencia'];

  selectedServicios = [];
  

  button_text= "enviar";

  faltanCampos = 0;
  faltaNombre = 0;
  faltaEmail = 0;
  faltaTel = 0;
  faltaPais = 0;
  faltaServicio = 0;
  faltaSegmento = 0;
  faltaMensaje = 0;

  constructor(public fb: UntypedFormBuilder,private server: ServerService, private metaService: Meta) {
    this.contacto = this.fb.group({
      nombre:         ['', []],
      email:          ['', []],
      tel:            ['', []],
      pais:         ['', []],
      servicio:           ['', []],
      servicioTexto:      ['', []],
      segmento:      ['', []],
      segmentoTexto:      ['', []],
      mensaje:        ['', []]
    });
    this.contacto.controls['pais'].setValue(this.default_pais, {onlySelf: true});
  

  }

  saveData() {
    if(this.formularioValido() == false){
      return;
    };
    this.button_text = "enviando...";
    this.contacto.value.asunto = "Consulta Web Proveedor: Bazar de Turismo";
    this.server.submitEmail(this.contacto.value).subscribe(val => console.log(val));
    this.button_text = "¡mensaje enviado!";
    this.contacto.reset();
  }


  ngOnInit() {

    this.metaService.addTags([
      {name: 'description', content: 'Nacimos para motorizar el cambio en la industria del turismo. Contáctate con nosotros por más información: Sede Montevideo. Ruta 8, Km 17500. Montevideo, Zonamerica - Edificio Biotec Local 005'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }

  
  formularioValido() {
   
    this.faltanCampos = 0;
    if(this.contacto.value.nombre == "" ){                                                                  this.faltaNombre++;         this.faltanCampos++; }else{       this.faltaNombre = 0;         }
    if(this.contacto.value.email == "" ){                                                                   this.faltaEmail++;          this.faltanCampos++; }else{       this.faltaEmail = 0;          }
    if(this.contacto.value.tel == "" ){                                                                     this.faltaTel++;            this.faltanCampos++; }else{       this.faltaTel = 0;            }
    if(this.contacto.value.pais == "" || this.contacto.value.pais == this.default_pais ){                   this.faltaPais++;           this.faltanCampos++; }else{       this.faltaPais = 0;           }
    if(this.contacto.value.segmentoTexto == "" ){                   this.faltaSegmento++;           this.faltanCampos++; }else{       this.faltaSegmento = 0;           }
    if(this.contacto.value.servicioTexto == "" ){    this.faltaServicio++;      this.faltanCampos++; }else{       this.faltaServicio = 0;      }
    if(this.contacto.value.mensaje == "" ){                                                                 this.faltaMensaje++;        this.faltanCampos++; }else{       this.faltaMensaje = 0;        } 
    if(this.faltanCampos > 0){                                                                              return false;   }
      return true;
  }



  showSelect(id){
    if(id == "segmentos" && this.showSegmentos == 0 ){
      this.showSegmentos = 1 ;
    }
    else if(id == "segmentos" && this.showSegmentos == 1 ){
      this.showSegmentos = 0 ;
    }

    if(id == "servicios" && this.showServicios == 0){
      this.showServicios = 1 ;
    }
    else if(id == "servicios" && this.showServicios == 1 ){
      this.showServicios = 0 ;
    }
    
  }



  selectChangeServicio () {
    //update the ui
    this.selectedServicios;

    let serviciosElejidos = "";

    this.selectedServicios.forEach(
      element => (
        serviciosElejidos += " "+element+","
      )
      );
      if(serviciosElejidos != ""){
        serviciosElejidos = serviciosElejidos.slice(0, -1);  
      }
      
      this.contacto.controls['servicioTexto'].setValue(serviciosElejidos);
     
  }

  selectChangeSegmento () {
    //update the ui
    this.selectedSegmentos;

    let segmentosElejidos = "";

    this.selectedSegmentos.forEach(
      element => (
        segmentosElejidos += " "+element+","
      )
      );
      if(segmentosElejidos != ""){
        segmentosElejidos = segmentosElejidos.slice(0, -1);  
      }
      
      this.contacto.controls['segmentoTexto'].setValue(segmentosElejidos);
     
  }

  tabToggle(index){
    if(index == this.showTab){
      this.showTab = 0 ;
    }
    else{
      this.showTab =index;
    }
    
  }
}
