<footer id="myfooter">



            <div class="footer-content  pt-5 pb-4">
                <div class="container">
                    <div class="row">
                        <div class="footer-links col-sm-6 col-md-3 py-3 ">
                            <h3>bazar de turismo</h3>
                            <ul>
                                <li><a routerLink="quienes-somos">quienes somos</a></li>
                                <!--<li><a routerLink="/">prensa</a></li>-->
                            </ul>
                        </div><!-- /footer-links -->
                        <div class="footer-links col-sm-6 col-md-3 py-3">
                            <h3>soporte</h3>
                            <ul>
                                <li><a routerLink="faqs">preguntas frecuentes</a></li>
                                <li><a routerLink="contacto">contacto</a></li>
                            </ul>
                        </div><!-- /footer-links -->
                        <div class="footer-links col-sm-6 col-md-3 py-3">
                            <h3>políticas de uso</h3>
                            <ul>
                                <li><a routerLink="condiciones-generales">condiciones generales</a></li>
                                <li><a routerLink="aviso-legal">aviso legal</a></li>
                                <li><a routerLink="politicas-privacidad">políticas de privacidad</a></li>
                            </ul>
                        </div><!-- /footer-links -->
                        <div class="footer-links col-sm-6 col-md-3 py-3">
                            <h3>trabaja con nosotros</h3>
                            <ul>
                                <li><a target="_blank" href="https://beetest-123.web.app/#/auth/login">proveedor</a></li>
                                <li><a target="_blank" href="https://beetest-123.web.app/#/auth/login">agencias</a></li>
                                <li><a routerLink="trabaja-con-nosotros">empleo</a></li>
                            </ul>
                        </div><!-- /footer-links -->
                    </div><!-- /row -->
                </div><!-- /container -->    
            </div>    
        
            <div class=" row-logos ml-0 mr-0 pt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-md-9 pt-1">
                            <a class="logo" routerLink="/"><img src="/assets/images/logos/logo.svg" alt="TravelAgency - Viajes y Turismo"/> </a><div class="copyright">© BazardeTurismo 2022</div> 
                        </div>

                        <div class="col-md-3 redes-sociales-footer">
                            <div class="row">
                                <span class="col"><a title="Instagram" href="https://www.instagram.com/bazardeturismo/" target="_blank" class="social-links text-muted"><i class="lab la-instagram"></i></a></span>
                                <span class="col"><a title="Linkedin" href="https://www.linkedin.com/company/bazardeturismo/" target="_blank" class="social-links text-muted"><i class="lab la-linkedin"></i></a></span>
                                <span class="col"><a title="Facebook" href="https://www.facebook.com/Bazar-de-Turismo-104263015140528" target="_blank" class="social-links text-muted"><i class="lab la-facebook"></i></a></span>
                            </div>
                        </div>
                    </div>                        
                </div>
            </div>
       

</footer>