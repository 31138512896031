<div class="slider">
  <div class="content-slider container">
    <div class="content-slider-info">
      <h2>nuestro equipo</h2>
      <p>
        integramos un equipo de profesionales<br>
        que equilibran la amplia experiencia  en la<br>
        gestión de empresas turísticas y el uso de<br>
        todos los recursos tecnológicos.
      </p>
      <button routerLink="/contacto" class="button btn-wihte">contáctanos</button><button routerLink="/pricing" class="button btn-blue ml-4">nuestras soluciones</button>
    </div>
   
  </div>
</div> 
<div class="container pt-4">
  <div class="row container">
    <div class="col-sm-12 pt-5">
      

      <div style="display:none;" class="section-nuestro-equipo-presentacion container mt-5 mb-5">
        <div class="row mt-5">
          
          
          
          
          
          
          
          
        <div class="col-md-2"> 
          <img src="/assets/images/staff/staff_0006_Fondo.jpg" />
          <h4>PAULA </h4>
        </div>
        <div class="col-md-2"> 
          <img src="/assets/images/staff/staff_0004_Capa3.jpg" />
          <h4>VICTORIA</h4>
        </div>
        <div class="col-md-2"> 
          <img src="/assets/images/staff/staff_0000_lucas.jpg" />
          <h4>LUCAS</h4>
        </div>
        <div class="col-md-2"> 
          <img src="/assets/images/staff/staff_0000_Capa6.jpg" />
          <h4>OSCAR </h4>
        </div>
        <div class="col-md-2"> 
          <img src="/assets/images/staff/staff_0003_Capa7.jpg" />
          <h4>LILIANA</h4>
        </div>
        <div class="col-md-2"> 
          <img src="/assets/images/staff/staff_0001_Capa5.jpg" />
          <h4>BARBARA</h4>
        </div>
        <div class="col-md-2"> 
          <img src="/assets/images/staff/staff_0005_Capa2.jpg" />
          <h4>CRISTIAN</h4>
        </div>
        <div class="col-md-2"> 
          <img src="/assets/images/staff/staff_0003_Capa7.jpg" />
          <h4>MARIANA</h4>
        </div>
        <div class="col-md-12"> </div>

   
      <button routerLink="/pricing" class="button btn-blue mt-4 full-width">ver soluciones</button>
      
       
        
        </div>
      </div> 


      <div class="section-home-newsletter container mb-6">
        <div class="title pt-5 mt-5 mb-5">
          <h2>contáctate con nosotros por más información</h2>
        </div>  
        <div class="row">
          <div class="col-md-4"> 
            <form (ngSubmit)="onSubmit()" #formNews="ngForm" [formGroup]="news">
              <input class="input" type="text" formControlName="email" name="email"  [ngClass]="{'aviso-input': faltaEmail > 0 }" placeholder="ingresa tu e-mail">
              <button class="button btn-blue ml-4 suscribite">{{button_text}}</button>   
            </form>
             <div [ngClass]="{'aviso-text': faltanCampos > 0 }" class="col-sm-6 pt-4 pl-3 position-absolute-25bt hidden">
                 debe ingresar su e-mail
             </div>
           </div>
          <div class="col-md-4">
            <button routerLink="/pricing" class="button btn-blue ml-4 full-width">ver soluciones</button>   
          </div>
          <div class="col-md-4">
            <button routerLink="/contacto" class="button btn-blue ml-4 full-width">contáctanos</button>   
          </div>
        </div> 
      </div>

      <p-dialog header="Titulo" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
        Muchas gracias por su interés en nuestra compañía, lo mantendremos informado de todas las novedades.
        <p-footer>
          <button type="button" pButton icon="pi pi-check" (click)="displayBasic=false" label="Yes">OK</button>
        </p-footer>
      </p-dialog>
     
    </div>
  </div>
</div>