import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-politicas-privacidad',
  templateUrl: './politicas-privacidad.component.html',
  styleUrls: ['./politicas-privacidad.component.scss']
})
export class PoliticasPrivacidadComponent implements OnInit {
  showTab = 0;
 

  constructor(private metaService: Meta) { }

  ngOnInit() {
    this.metaService.addTags([
      {name: 'description', content: 'Nacimos para motorizar el cambio en la industria del turismo. Contáctate con nosotros por más información: Sede Montevideo. Ruta 8, Km 17500. Montevideo, Zonamerica - Edificio Biotec Local 005'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }


  tabToggle(index){
    if(index == this.showTab){
      this.showTab = 0 ;
    }
    else{
      this.showTab =index;
    }
    
  }
}
