<div class="container pt-4 mb-5">
  <div class="row">
    <div class="col-sm-12 pt-5">
      <div _ngcontent-bnr-c3="" class="title mb-5"><h2 _ngcontent-bnr-c3="">condiciones generales</h2></div>
      

      <p>El presente sitio web (en adelante, el &ldquo;Sitio Web&rdquo;) utiliza cookies para realizar un seguimiento de las interacciones de los usuarios con los servicios que ofrece el titular del sitio web, PYTHAGORAS SOFTWARE SOLUTIONS SRL y mejorar su experiencia en el sitio web.&nbsp;1.- Qu&eacute; son las cookies:&nbsp;Las cookies son peque&ntilde;os archivos de texto que se descargan y almacenan en el terminal (ordenador/smartphone/tablet) del usuario al acceder a determinadas p&aacute;ginas web y que permiten al sitio web recordar preferencias de navegaci&oacute;n y navegar de manera eficiente, haciendo que la interacci&oacute;n entre el usuario y el sitio web sea m&aacute;s r&aacute;pida y f&aacute;cil.<br />
        <br />
        La informaci&oacute;n recogida por las cookies u otros dispositivos de almacenamiento es totalmente an&oacute;nima y no contiene ninguna informaci&oacute;n sensible (como el nombre, direcci&oacute;n o detalles bancarios) ya que &eacute;stas no recogen datos que puedan identificar personalmente al usuario.&nbsp;2.- Tipos de cookies utilizadas:&nbsp;El presente Sitio Web utiliza cookies, p&iacute;xeles u otros dispositivos de almacenamiento y recuperaci&oacute;n de informaci&oacute;n para realizar un seguimiento de las interacciones de los usuarios en el Sitio Web. Los tipos de cookies que utiliza el Sitio Web son los siguientes:<br />
        &nbsp;</p>
        
        
        
        <p><b>Cookies propias:</b> Cookies de PYTHAGORAS SOFTWARE SOLUTIONS SRL: Estas cookies permiten que el sitio Web funcione correctamente por lo que son esenciales para que el usuario pueda usar todas las opciones del Sitio Web y pueda moverse por el mismo con normalidad. Permiten, por ejemplo, guardar sus preferencias personales.<br />
        &nbsp;</p>
        
        <table class="datos">
          <tbody>
            <tr>
              <th width="20%">Empresa</th>
              <th width="30%">Informaci&oacute;n</th>
              <th width="50%">Finalidad</th>
            </tr>
            <tr>
              <td>PYTHAGORAS SOFTWARE SOLUTIONS SRL</td>
              <td>Ubicaci&oacute;n del usuario y preferencias personales</td>
              <td>Permiten ofrecer un servicio m&aacute;s personalizado a cada visitante, as&iacute; como guardar las preferencias personales, como por ejemplo la divisa en la que se muestran los precios.</td>
            </tr>
          </tbody>
        </table>
        
        <p>Cookies de Terceros</p>
        
        <p><b>Cookies de anal&iacute;tica</b>: Estas cookies permiten recopilar informaci&oacute;n an&oacute;nima sobre la navegaci&oacute;n de los usuarios por el Sitio Web y sus patrones de comportamiento, con el fin de conocer el origen de las visitas y otros datos estad&iacute;sticos, as&iacute; como de mejorar el funcionamiento del servicio.<br />
        &nbsp;</p>
        
        <table>
          <tbody>
            <tr>
              <th width="20%">Empresa</th>
              <th width="30%">Informaci&oacute;n</th>
              <th width="50%">Finalidad</th>
              <th>&nbsp;</th>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>P&aacute;ginas visitadas, tiempo de navegaci&oacute;n, origen del visitante, ubicaci&oacute;n...</td>
              <td>Informes estad&iacute;sticos sobre como los usuarios encuentran la p&aacute;gina web, c&oacute;mo la utilizan y si esta funciona correctamente</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>New Relic</td>
              <td>Informaci&oacute;n sobre la navegaci&oacute;n</td>
              <td>Informes sobre como los usuarios utilizan la web y si esta funciona correctamente</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <br><br>
        
        <p><b>Cookies de publicidad</b>: Permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios que, el editor haya incluido en el Sitio Web, aplicaci&oacute;n o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios. Permiten ofrecer publicidad af&iacute;n a los intereses del Usuario.<br />
        &nbsp;</p>
        
        <table>
          <tbody>
            <tr>
              <th width="20%">Empresa</th>
              <th width="30%">Informaci&oacute;n</th>
              <th width="50%">Finalidad</th>
              <th>&nbsp;</th>
            </tr>
            <tr>
              <td>Doubleclick (Google, Inc.)</td>
              <td>Informaci&oacute;n sobre los anuncios que han sido mostrados a un usuario, sobre los que le interesan y sobre si visita la p&aacute;gina web del anunciante</td>
              <td>Mejorar y gestionar la exposici&oacute;n de anuncios publicitarios al usuario evitando que aparezcan anuncios que ya ha visto</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Tradedoubler</td>
              <td>Informaci&oacute;n sobre el anuncio mostrado al usuario</td>
              <td>Permite analizar la interacci&oacute;n de un usuario con un anuncio</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>Datos del usuario en Facebook y su navegaci&oacute;n en la p&aacute;gina actual</td>
              <td>Permiten compartir el contenido de una p&aacute;gina y saber si a tus amigos tambi&eacute;n les gusta, tambi&eacute;n personalizar los anuncios dentro de Facebook</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>

        <br><br>
        
        <p>3.- Web beacons o web bug:&nbsp;Este Sitio Web tambi&eacute;n utiliza Web Beacons, que suelen ser peque&ntilde;as im&aacute;genes de un pixel por un pixel, visibles o invisibles colocados dentro del c&oacute;digo fuente de algunos sitios web, y tienen una finalidad similar a las cookies. Los Web Beacons suelen utilizarse a modo estad&iacute;stico, para medir el tr&aacute;fico de usuarios que visitan una p&aacute;gina web y poder sacar un patr&oacute;n de los usuarios de un sitio web. Los datos obtenidos a trav&eacute;s de los Web Beacons son en todo caso an&oacute;nimos.&nbsp;4.- C&oacute;mo deshabilitar las cookies y otros dispositivos:&nbsp;La mayor&iacute;a de navegadores web permiten gestionar las preferencias de cookies u otros dispositivos de almacenamiento, para tener un control m&aacute;s preciso sobre la privacidad.<br />
        <br />
        Los siguientes links muestran las instrucciones para desactivar la configuraci&oacute;n de las cookies en cada navegador:<br />
        &nbsp;</p>
        
        <h4>1. Internet Explorer</h4>
        
        <ul>
          <li>En el men&uacute; de herramientas, seleccione &ldquo;Opciones de Internet&rdquo;.</li>
          <li>Haga clic en la pesta&ntilde;a de privacidad.</li>
          <li>Podr&aacute; configurar la privacidad con un cursor con seis posiciones que le permite controlar la cantidad de cookies que se instalar&aacute;n: Bloquear todas las cookies, Alta, Media Alto, Media (nivel por defecto), Baja, y Aceptar todas las cookies.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <h4>2. Mozilla Firefox&nbsp;</h4>
        
        <ul>
          <li>En la parte superior de la ventana de Firefox hacer clic en el men&uacute; Herramientas.</li>
          <li>Seleccionar Opciones.</li>
          <li>Seleccionar el panel Privacidad.</li>
          <li>En la opci&oacute;n Firefox podr&aacute; elegir Usar una configuraci&oacute;n personalizada para el historial para configurar las opciones.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <h4>3. Google Chrome&nbsp;</h4>
        
        <ul>
          <li>Hacer clic en el men&uacute; situado en la barra de herramientas.</li>
          <li>Seleccionar Configuraci&oacute;n.</li>
          <li>Hacer clic en Mostar opciones avanzadas.</li>
          <li>En la secci&oacute;n &#39;Privacidad&#39; hacer clic en el bot&oacute;n Configuraci&oacute;n de contenido.</li>
          <li>En la secci&oacute;n de &#39;Cookies&#39; se pueden configurar las opciones.</li>
        </ul>
        
        <p>&nbsp;</p>
        
        <h4>4. Safari</h4>
        
        <ul>
          <li>En el men&uacute; de configuraci&oacute;n, seleccione la opci&oacute;n de &ldquo;preferencias&rdquo;.</li>
          <li>Abra la pesta&ntilde;a de privacidad.</li>
          <li>Seleccione la opci&oacute;n que quiera de la secci&oacute;n de &ldquo;bloquear cookies&rdquo;.</li>
          <li>Recuerde que ciertas funciones y la plena funcionalidad del Sitio pueden no estar disponibles despu&eacute;s de deshabilitar los cookies.</li>
        </ul>
        
        <p>Si desea no ser rastreado por las cookies, Google ha desarrollado un complemento para instalar en su navegador.</p>
        
        <h4>5.- Cookies en los dispositivos m&oacute;viles:&nbsp;</h4>
        <p>El presente Sitio Web tambi&eacute;n usa cookies u otros dispositivos de almacenamiento en dispositivos m&oacute;viles. En este caso, al igual que sucede en los navegadores de ordenadores, los navegadores de los dispositivos m&oacute;viles permiten configurar las opciones o ajustes de privacidad para desactivar o eliminar las cookies.<br />
        <br />
        Si desea modificar las opciones de privacidad siga las instrucciones especificadas por el desarrollador de su navegador para dispositivo m&oacute;vil.<br />
        <br />
        Asimismo, a continuaci&oacute;n podr&aacute; encontrar algunos ejemplos de los links que le guiar&aacute;n para modificar las opciones de privacidad en su dispositivo m&oacute;vil:</p>
        
        <ul>
          <li>IOS</li>
          <li>Windows Phone</li>
          <li>Chrome Mobile</li>
          <li>Opera Mobile</li>
        </ul>
        
        <h4>6.- Aceptaci&oacute;n de cookies:</h4>
        <p>&nbsp;Si usted contin&uacute;a su navegaci&oacute;n, entenderemos que acepta la utilizaci&oacute;n de las cookies por parte del sitio web.<br />
        <br />
        Le informamos que en el caso de bloquear o no aceptar la instalaci&oacute;n de cookies, es posible que ciertos servicios no est&eacute;n disponibles sin la utilizaci&oacute;n de &eacute;stas o que no pueda acceder a determinados servicios ni tampoco aprovechar por completo todo lo que este sitio web le ofrece.</p>
        
     
    </div>
  </div>
</div>