import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { ServerService } from '../server.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  showTab = 0;
  contacto: UntypedFormGroup;


  button_text= "enviar";

  faltanCampos = 0;
  faltaNombre = 0;
  faltaEmail = 0;
  faltaTel = 0;
  faltaMensaje = 0;

  constructor(public fb: UntypedFormBuilder,private server: ServerService, private metaService: Meta) {
    this.contacto = this.fb.group({
      nombre:         ['', []],
      email:          ['', []],
      tel:            ['', []],
      mensaje:        ['', []]
    });
  }

  saveData() {
    if(this.formularioValido() == false){
      return;
    };
    this.button_text = "enviando...";
    this.contacto.value.asunto = "Consulta Web: Bazar de Turismo";
    this.server.submitEmail(this.contacto.value).subscribe(val => console.log(val));
    this.button_text = "¡mensaje enviado!";
    this.contacto.reset();
  }


  ngOnInit() {

    this.metaService.addTags([
      {name: 'description', content: 'Nacimos para motorizar el cambio en la industria del turismo. Contáctate con nosotros por más información: Sede Montevideo. Ruta 8, Km 17500. Montevideo, Zonamerica - Edificio Biotec Local 005'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }

  
  formularioValido() {
    this.faltanCampos = 0;
    if(this.contacto.value.nombre == "" ){  this.faltaNombre++;   this.faltanCampos++; }else{ this.faltaNombre = 0;   }
    if(this.contacto.value.email == "" ){   this.faltaEmail++;    this.faltanCampos++; }else{ this.faltaEmail = 0;    }
    if(this.contacto.value.tel == "" ){     this.faltaTel++;      this.faltanCampos++; }else{ this.faltaTel = 0;      }
    if(this.contacto.value.mensaje == "" ){ this.faltaMensaje++;  this.faltanCampos++; }else{ this.faltaMensaje = 0;  } 
    if(this.faltanCampos > 0){    return false;   }
    return true;
  }


  tabToggle(index){
    if(index == this.showTab){
      this.showTab = 0 ;
    }
    else{
      this.showTab =index;
    }
    
  }
}
