<div class="slider slider-faqs">
  <div class="content-slider container">
    <div class="content-slider-info">
      <h2>preguntas frecuentes</h2>
      <p>
        un innovador modelo de negocios que aporta<br>
        nuevas normas y jugadores a la venta de viajes
      </p>
      <button class="button btn-blue">nuestra empresa</button>
    </div>
    <div class="content-slider-img">
      <img class="draw-images" src="/assets/images/draw_4.svg" />
    </div>
   
  </div>
</div> 
<div class="container pt-4 faq">
  <div class="row container">
    <div class="col-sm-12 pt-5">
      

      <div class="section-home-beneficios container mt-5 mb-5">
        <div class="title">
          <h2 style="margin-bottom: 18px;">un mercado global necesitaba una herramienta integral</h2>
        </div>
        <p>
          Tendrás la oportunidad de poder ofrecer tu propio inventario e inventarios complementarios a los tuyos a través de la conexión transparente con proveedores externos. De esta manera puedes ofrecer a tus clientes una variedad de servicios y paquetes dinámicos que hasta ahora eran imposibles de gestionar desde una sola Web.<br>
          De este modo, tu compañía podrá ofrecer simultáneamente tus propios productos y/o los de proveedores externos, dando a tus clientes la opción de escoger entre una amplia gama de productos en tu Web como valor añadido.
        </p>
        <div class="row mt-5">
          <div class="col-md-6">
            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(1)">01</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 1}" (click)="tabToggle(1)" >Control total Administrativo <div class="icon-item"><i *ngIf="showTab == 1" class="las la-angle-up"></i><i *ngIf="showTab != 1" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 1" class="text-item">El más robusto y completo ERP 100% online. Versátil, usable, una herramienta innovadora y con todas las herramientas que tu compañía necesita para tener un control administrativo eficiente y confiable</div>
            </div>
            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(2)">02</div>
              <div class="title-item"  [ngClass]="{'color-principal': showTab == 2}"  (click)="tabToggle(2)" >Una experiencia de usuario diferente <div class="icon-item"><i *ngIf="showTab == 2" class="las la-angle-up"></i><i *ngIf="showTab != 2" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 2" class="text-item">La interfaz ha sido diseñada para cambiar la complejidad en la cotización, la paquetización y la creación de itinerarios de las ventas on-line en una experiencia sencilla y que permite ahorrar tiempo.</div>
            </div>
            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(3)">03</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 3}"  (click)="tabToggle(3)" >Mucho más que B2B y B2C <div class="icon-item"><i *ngIf="showTab == 3" class="las la-angle-up"></i><i *ngIf="showTab != 3" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 3" class="text-item">Llegamos para generar un cambio, abrir mercados y democratizar la industria de los viajes. Tienda de Turismo es nuevo concepto que permite llegar de forma más eficiente al viajero.</div>
            </div>
            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(4)">04</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 4}"  (click)="tabToggle(4)" >Personaliza tu portal web<div class="icon-item"><i *ngIf="showTab == 4" class="las la-angle-up"></i><i *ngIf="showTab != 4" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 4" class="text-item">Podrás añadir los datos de tu agencia: logo, dirección, horarios de apertura, redes sociales y mucho más control. Tu agencia. Tu portal. Tu estrategia comercial.</div>
            </div>
            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(5)">05</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 5}"  (click)="tabToggle(5)" >Uso de dominio propio<div class="icon-item"><i *ngIf="showTab == 5" class="las la-angle-up"></i><i *ngIf="showTab != 5" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 5" class="text-item">Si ya posees un dominio propio, configúralo para gestionar tu web. Ella te dará la posibilidad de una web actualizada 365 días del año sin invertir en tecnología.</div>
            </div>
            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(6)">06</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 6}" (click)="tabToggle(6)" >Incluye contratos con tus proveedores vía XML <div class="icon-item"><i *ngIf="showTab == 6" class="las la-angle-up"></i><i *ngIf="showTab != 6" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 6" class="text-item">Múltiples proveedores de transporte, alojamiento, servicios terrestres, circuitos y paquetes integrados. Un sistema de reservas muy flexible y con variedad de opciones.</div>
            </div>
          </div>  



          <div class="col-md-6">
            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(7)">07</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 7}"  (click)="tabToggle(7)" >Carga de productos y servicios <div class="icon-item"><i *ngIf="showTab == 7" class="las la-angle-up"></i><i *ngIf="showTab != 7" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 7" class="text-item">Nuestra plataforma permite cargar producto propio desde vuelos, paquetes, actividades, hoteles y servicios ¡todo lo que sea necesario! y 100% disponible para la red de clientes.</div>
            </div>
            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(8)">08</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 8}"  (click)="tabToggle(8)" >100% integrado con Tienda de Turismo <div class="icon-item"><i *ngIf="showTab == 8" class="las la-angle-up"></i><i *ngIf="showTab != 8" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 8" class="text-item">Además de tu sitio web de reservas incluído en la licencia, podrás formar parte de Tienda de Turismo, nuestro Marketplace donde podrás conectar miles de productos con confirmación online. Productos dinámicos en tu portal web con una amplia conectividad y el set de herramientas más versátil.</div>
            </div>

            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(9)">09</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 9}"  (click)="tabToggle(9)" >Marca tu propia rentabilidad <div class="icon-item"><i *ngIf="showTab == 9" class="las la-angle-up"></i><i *ngIf="showTab != 9" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 9" class="text-item">Configura tus reglas de negocios para hoteles, paquetes, circuitos y mucho más.</div>
            </div>

            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(10)">10</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 10}"  (click)="tabToggle(10)" >Creación de múltiples roles de usuario <div class="icon-item"><i *ngIf="showTab == 10" class="las la-angle-up"></i><i *ngIf="showTab != 10" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 10" class="text-item">Asignación de roles de manera intuitiva que permite vincular las herramientas acordes a las funciones específicas.</div>
            </div>

            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(11)">11</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 11}"  (click)="tabToggle(11)" >Instalación de Widgets (En desarrollo) <div class="icon-item"><i *ngIf="showTab == 11" class="las la-angle-up"></i><i *ngIf="showTab != 11" class="las la-angle-down"></i></div></div>
              <div *ngIf="showTab == 11" class="text-item">Instalar un chat online o un sistema de notificaciones autónomo para interactuar con tus usuarios online. Plataforma de pagos online.</div>
            </div>

            <div class="select-option" role="button">
              <div class="number-item" (click)="tabToggle(12)">12</div>
              <div class="title-item" [ngClass]="{'color-principal': showTab == 12}"  (click)="tabToggle(12)" >Y mucho más...  <div class="icon-item"></div></div>
              <div *ngIf="showTab == 12" class="text-item"></div>
            </div>
          </div>
        </div>
      </div> 

      <div class="section-home-newsletter container mb-6">
        <div class="title pt-5 mt-5 mb-5">
          <h2>contáctate con nosotros por más información</h2>
        </div>  
        <div class="row">
          <div class="col-md-4"> 
            <form (ngSubmit)="onSubmit()" #formNews="ngForm" [formGroup]="news">
              <input class="input" type="text" formControlName="email" name="email"  [ngClass]="{'aviso-input': faltaEmail > 0 }" placeholder="ingresa tu e-mail">
              <button class="button btn-blue ml-4 suscribite">{{button_text}}</button>   
            </form>
             <div [ngClass]="{'aviso-text': faltanCampos > 0 }" class="col-sm-6 pt-4 pl-3 position-absolute-25bt hidden">
                 debe ingresar su e-mail
             </div>
           </div>
          <div class="col-md-4">
            <button routerLink="/pricing" class="button btn-blue ml-4 full-width mb-2">ver soluciones</button>   
          </div>
          <div class="col-md-4">
            <button routerLink="/contacto" class="button btn-blue ml-4 full-width">contáctanos</button>   
          </div>
        </div> 
      </div>

      <p-dialog header="Titulo" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
        Muchas gracias por su interés en nuestra compañía, lo mantendremos informado de todas las novedades.
        <p-footer>
          <button type="button" pButton icon="pi pi-check" (click)="displayBasic=false" label="Yes">OK</button>
        </p-footer>
      </p-dialog>
     
    </div>
  </div>
</div>