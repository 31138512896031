import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor(private metaService: Meta) { }

  ngOnInit() {
    this.metaService.addTags([
      {name: 'description', content: 'Nacimos para motorizar el cambio en la industria del turismo. Contáctate con nosotros por más información: Sede Montevideo. Ruta 8, Km 17500. Montevideo, Zonamerica - Edificio Biotec Local 005'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }

}
