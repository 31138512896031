<div class="container pt-4 mb-5">
  <div class="row">
    <div class="col-sm-12 pt-5">
      <div _ngcontent-bnr-c3="" class="title mb-5"><h2 _ngcontent-bnr-c3="">políticas de privacidad</h2></div>
      <p>bazardeturismo.com (en adelante &ldquo; PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo&rdquo; o &ldquo;Nosotros&rdquo;) valora a sus usuarios y est&aacute; comprometida a proteger su privacidad. Para cumplir con este compromiso, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo ha desarrollado esta pol&iacute;tica de privacidad (en adelante, la &ldquo;Pol&iacute;tica de Privacidad&rdquo; o &ldquo;Pol&iacute;tica&rdquo;) que describe las pr&aacute;cticas de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo en lo que se refiere a la recolecci&oacute;n, uso, conservaci&oacute;n y divulgaci&oacute;n de informaci&oacute;n personal recopilada.</p>

<p>Cuando Usted utiliza los servicios de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo, sea para realizar b&uacute;squedas, registrarse para recibir ofertas y novedades o contratar alg&uacute;n servicio a trav&eacute;s de cualquiera de sus canales de venta -centro de atenci&oacute;n telef&oacute;nica, sitio web&nbsp;(en adelante, el &ldquo;Sitio Web&rdquo;) o aplicaci&oacute;n para tel&eacute;fonos m&oacute;viles y tabletas (en adelante, la &ldquo;Aplicaci&oacute;n&rdquo;)- nos conf&iacute;a informaci&oacute;n. Esta Pol&iacute;tica de Privacidad est&aacute; destinada a ayudarlo a entender qu&eacute; datos recopilamos, por qu&eacute; los recopilamos y qu&eacute; hacemos con ellos.</p>

<p>Usted, en tanto usuario del mismo, acepta las pr&aacute;cticas que se detallan a continuaci&oacute;n, por lo que le recomendamos leer detenidamente la informaci&oacute;n que vamos a brindarle.</p>

<p>El responsable de la base de datos/fichero en Argentina es PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo.com.ar S.A., domiciliado en Maipu 464, Ciudad Aut&oacute;noma de Buenos Aires, Argentina.</p>

<h4>&iquest;Qu&eacute; informaci&oacute;n recopilamos?</h4>

<p>PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo recibe y almacena a) Informaci&oacute;n que Usted proporciona, b) informaci&oacute;n obtenida de terceros c) informaci&oacute;n p&uacute;blica, y d) informaci&oacute;n que recopilan nuestros sistemas, seg&uacute;n se describe a continuaci&oacute;n:</p>

<h4>a)&nbsp;Informaci&oacute;n que Usted nos proporciona:</h4>

<p>Para poder realizar reservas tur&iacute;sticas en PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo, Usted debe proporcionarnos cierta informaci&oacute;n personal (en adelante la &ldquo;Informaci&oacute;n Personal&rdquo;) que incluye nombre y apellido, n&uacute;mero de documento o identificaci&oacute;n v&aacute;lida, nacionalidad, clave de identificaci&oacute;n tributaria, e informaci&oacute;n f&iacute;sica de contacto (como n&uacute;mero telef&oacute;nico, domicilio o direcci&oacute;n de correo electr&oacute;nico). A fin de formalizar reservas tur&iacute;sticas, Usted debe adem&aacute;s brindarnos la informaci&oacute;n sobre su tarjeta de d&eacute;bito o cr&eacute;dito (tal como el n&uacute;mero de la tarjeta de cr&eacute;dito, el c&oacute;digo de seguridad, el nombre del titular de la tarjeta y la fecha de vencimiento). A su exclusivo criterio, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo podr&aacute; confirmar la Informaci&oacute;n Personal suministrada acudiendo a entidades p&uacute;blicas, compa&ntilde;&iacute;as especializadas o centrales de riesgo, para lo cual Usted nos autoriza expresamente. La informaci&oacute;n que PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo obtenga de estas entidades ser&aacute; tratada en forma confidencial.</p>

<p>Usted siempre podr&aacute; elegir no darnos informaci&oacute;n, pero en general se requiere cierta informaci&oacute;n sobre Usted para comprar o realizar transacciones en PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo. Si Usted elige no darnos determinada informaci&oacute;n, es posible que Usted no pueda contratar con Nosotros.</p>

<p>En caso que Usted realice una reserva en nombre de un tercero, deber&aacute; obtener previamente el consentimiento de dicho tercero antes de facilitarnos Informaci&oacute;n Personal de &eacute;ste. Si ese tercero es menor de edad seg&uacute;n la ley aplicable, Usted declara tener facultades suficientes para brindar dicha Informaci&oacute;n Personal y aceptar esta Pol&iacute;tica de Privacidad.</p>

<p>Usted tambi&eacute;n puede facilitar opiniones, comentarios y sugerencias participando voluntariamente de encuestas y/o contestando preguntas enviadas a trav&eacute;s de correos electr&oacute;nicos o desde el Sitio Web o la Aplicaci&oacute;n (las &ldquo;Opiniones&rdquo;). En este caso, a fin de brindar a otros usuarios testimonios reales sobre experiencias de servicios, Usted acepta y est&aacute; de acuerdo en que todas las Opiniones que nos env&iacute;e podr&aacute;n ser publicadas por en el Sitio Web y/o Aplicaci&oacute;n de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo, o en otros Sites y/o Aplicaciones con quienes PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo tenga relaciones contractuales. En tal caso, Usted podr&aacute; ser identific&aacute;ndolo con nombre completo y/o apodo, foto, comentario y nacionalidad. Al brindarnos su Opini&oacute;n, o subir fotos reales a trav&eacute;s del Sitio Web, Usted cede de manera irrevocable a PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo todos los derechos de propiedad sobre dichas fotograf&iacute;as y Opiniones. Le recordamos que Usted puede dar su opini&oacute;n de manera an&oacute;nima o eliminarla, seg&uacute;n procedimiento que se detalla más abajo en esta Pol&iacute;tica de Privacidad (punto 6.1.&nbsp;Pol&iacute;tica de renuncia (opt out).</p>

<p>Siempre tenga presente que cuando coloca informaci&oacute;n en un &aacute;rea p&uacute;blica de este Sitio Web (incluido sin limitar: avisos, grupos de chat, &aacute;lbumes de fotograf&iacute;as electr&oacute;nicos y comentarios sobre los productos y servicios), la est&aacute; poniendo a disposici&oacute;n de otros miembros, usuarios del Sitio Web y al p&uacute;blico en general; lo cual queda fuera del &aacute;mbito de control de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo. Por favor, recuerde lo anterior y sea cuidadoso con la informaci&oacute;n que decide divulgar.</p>

<h4>b)&nbsp;Informaci&oacute;n proporcionada por terceros</h4>

<p>En la medida permitida por la Ley, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo tambi&eacute;n podr&aacute; obtener Informaci&oacute;n Personal sobre Usted y a&ntilde;adirla a la Informaci&oacute;n Personal que Usted nos proporciona ya sea de entidades afiliadas, socios comerciales y otras fuentes de terceros independientes -como ser bases de datos p&uacute;blicas, informaci&oacute;n recopilada durante una conversaci&oacute;n telef&oacute;nica con el centro de atenci&oacute;n al cliente y/o a trav&eacute;s de aplicaciones interactivas-. Tenga en cuenta que toda la informaci&oacute;n que recopilamos sobre Usted puede combinarse con la Informaci&oacute;n Personal. Cualquier Informaci&oacute;n Personal obtenida por PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo por los medios aqu&iacute; descriptos ser&aacute; tratada de conformidad con las disposiciones de esta Pol&iacute;tica de Privacidad.</p>

<p>Si Usted se registra en PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo a trav&eacute;s de su cuenta personal en una red social (&quot;Cuenta Personal&quot;), consiente expresamente que PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo:</p>

<ul>
	<li>Tenga acceso, en cualquier momento, a la totalidad de la informaci&oacute;n contenida en su Cuenta Personal, incluyendo en particular, pero sin limitaci&oacute;n, a su Informaci&oacute;n Personal, informaci&oacute;n sobre sus intereses, gustos, contactos y cualquier otro contenido alojado en su Cuenta Personal.</li>
	<li>Incluya en dicha cuenta, mensajes, fotograf&iacute;as, videos y cualquier otro tipo de contenido. &nbsp;</li>
	<li>Env&iacute;e al correo electr&oacute;nico del Usuario vinculado a la Cuenta Personal o al correo electr&oacute;nico utilizado para realizar la reserva en PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo, informaci&oacute;n o mensajes con la finalidad descripta en la secci&oacute;n 3 &ldquo;&iquest;Para qu&eacute; utilizamos la informaci&oacute;n que recopilamos&rdquo; &nbsp;que se encuentra en esta Pol&iacute;tica.</li>
</ul>

<h4>c)&nbsp;Informaci&oacute;n que recopilan nuestros sistemas. Pol&iacute;tica de Cookies y web beacons.</h4>

<p>PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo recoge y almacena autom&aacute;ticamente cierta informaci&oacute;n sobre la actividad de sus Usuarios. Tal informaci&oacute;n puede incluir la URL de la que provienen (est&eacute;n o no en el Sitio Web de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo), a qu&eacute; URL acceden seguidamente, qu&eacute; navegador est&aacute;n usando, y sus direcciones IP. Tambi&eacute;n las p&aacute;ginas visitadas y las b&uacute;squedas realizadas. Para que el Sitio Web funcione correctamente, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo utiliza cookies propias y de terceros.</p>

<h4>&iquest;Qu&eacute; es una cookie? &iquest;Qu&eacute; son las web beacons&rdquo;?</h4>

<p>Las cookies son tipos espec&iacute;ficos de informaci&oacute;n que un sitio web transmite al disco duro de la computadora del Usuario con el fin de mantener los registros. Las cookies pueden servir para facilitar el uso de un sitio web, al guardar contrase&ntilde;as y preferencias mientras el Usuario navega en Internet. Por su parte, las &quot;web beacons&quot; son im&aacute;genes insertadas en una p&aacute;gina de Internet o correo electr&oacute;nico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar informaci&oacute;n sobre la direcci&oacute;n IP del usuario, duraci&oacute;n del tiempo de interacci&oacute;n en dicha p&aacute;gina y el tipo de navegador utilizado, entre otros.</p>

<h4>&iquest;C&oacute;mo se controlan las cookies?</h4>

<p>Aunque la mayor&iacute;a de los navegadores aceptan &ldquo;cookies&rdquo; y &ldquo;web beacons&rdquo; autom&aacute;ticamente, en todo momento, Usted podr&aacute; elegir no recibir un archivo de cookies habilitando su navegador web para que rechace cookies o le informe antes de aceptarlas (para obtener detalles, puede consultar&nbsp;<a href="http://www.aboutcookies.org/" target="_blank">&nbsp;www.aboutcookies.org</a>).</p>

<p>Tenga en cuenta que las preferencias de cookies deben configurarse para cada navegador (Internet Explorer, Google Chrome, Mozilla Firefox o Safari), y que al negarse a aceptar una cookie no podr&aacute; obtener acceso a muchos servicios de viaje y herramientas de planificaci&oacute;n ofrecidos por este Sitio Web.</p>

<h4>&iquest;C&oacute;mo utilizamos las cookies?</h4>

<p>Nuestro Sitio Web utiliza diferentes tipos de cookies:</p>

<p>Cookies t&eacute;cnicas:</p>

<p>se utilizan para el funcionamiento correcto del sitio web.</p>

<p>Cookies funcionales:</p>

<p>son necesarias para ofrecer los servicios solicitados por los usuarios del sitio web.</p>

<p>Cookies anal&iacute;ticas:</p>

<p>permiten ver el comportamiento de navegaci&oacute;n de los visitantes en el sitio web, as&iacute; como registrar los contenidos que ven y los que son de su inter&eacute;s. Esto nos ayuda a mejorar el servicio que ofrecemos, ya que de este modo podemos garantizar que nuestros usuarios encuentren la informaci&oacute;n que buscan.</p>

<p>Cookies sociales:</p>

<p>permiten proporcionar funcionalidades relacionadas con redes sociales (Facebook, Google y Twitter) al permitir y controlar la interacci&oacute;n con widgets sociales en el sitio web.</p>

<p>Cookies de publicidad:</p>

<p>recopilan informaci&oacute;n sobre tus preferencias y elecciones en el sitio web. Est&aacute;n destinadas a las redes publicitarias, que m&aacute;s tarde las usan para mostrarte publicidad personalizada en otros sitios web.</p>

<p>Cookies de identificaci&oacute;n:</p>

<p>permiten identificar a los visitantes y establecer coincidencias con los datos personales incluidos en nuestros archivos, para as&iacute; enviar comunicaciones personales pertinentes.</p>

<p>Las cookies son tipos espec&iacute;ficos de informaci&oacute;n que un sitio web transmite al disco duro de la computadora del Usuario con el fin de mantener los registros. Las cookies pueden servir para facilitar el uso de un sitio web, al guardar contrase&ntilde;as y preferencias mientras el Usuario navega en Internet.</p>

<h4>2. &iquest;Para qu&eacute; utilizamos la informaci&oacute;n que recopilamos?</h4>

<p>Usted autoriza expresamente a PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo a registrar y tratar su Informaci&oacute;n Personal para los siguientes fines:&nbsp;</p>

<ol>
	<li>Gestionar su reserva tur&iacute;stica, llevar a cabo las transacciones que usted haya iniciado y procesar facturas u otra documentaci&oacute;n fiscal;&nbsp;</li>
	<li>Ofrecerle productos y servicios y responder a sus preguntas y comentarios;</li>
	<li>Remitirle confirmaci&oacute;n y actualizaciones sobre su viaje, as&iacute; como cualquier informaci&oacute;n relevante al respecto. Esta informaci&oacute;n podr&aacute; ser remitida v&iacute;a correo electr&oacute;nico, SMS o Whatsapp al tel&eacute;fono celular suministrado por Usted. Estos mensajes podr&aacute;n contener (sin limitarse a) informaci&oacute;n sobre los servicios de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo, as&iacute; como datos de contacto de proveedores que podr&iacute;an ayudar a su experiencia de viaje en destino.</li>
	<li>Contactarnos con Usted para prop&oacute;sitos de servicio al cliente, realizar encuestas, estad&iacute;sticas o an&aacute;lisis sobre h&aacute;bitos de consumo o preferencias, notificarle por correo electr&oacute;nico las ofertas especiales y los productos y servicios relacionados con viajes que podr&iacute;an serle de inter&eacute;s&nbsp;salvo que usted no lo desee (ver m&aacute;s adelante: pol&iacute;tica de renuncia &ldquo;Opt. Out&rdquo;).</li>
</ol>

<p>Con el prop&oacute;sito de ofrecer mejores servicios o proveer a sus Usuarios informaci&oacute;n relacionada con sus preferencias, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo podr&aacute; tambi&eacute;n desarrollar estudios internos sobre los intereses, comportamientos y demograf&iacute;a de los usuarios, para comprender mejor sus necesidades e intereses, mejorar nuestras iniciativas comerciales y promocionales, personalizar sus contenidos, su presentaci&oacute;n y servicios, mostrar publicidad o promociones, banners, de inter&eacute;s para los nuestros usuarios.</p>

<p>Asimismo, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo utiliza la informaci&oacute;n de las tarjetas de d&eacute;bito o cr&eacute;dito (tal como nombre del titular de la tarjeta, n&uacute;mero de la tarjeta y fecha de vencimiento) solamente para el prop&oacute;sito de completar las reservas de viaje que Usted realiza, incluyendo el env&iacute;o de sus datos a los proveedores finales de servicios, para gestionar sus reservas y/o solicitudes de compra. Asimismo, Usted puede optar porque PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo recuerde ciertos datos de su tarjeta y facturaci&oacute;n, los cuales aparecer&aacute;n en forma autom&aacute;tica al ingresar al Sitio Web y/o Aplicaci&oacute;n de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo.</p>

<h4>3.&nbsp;&iquest;Con qui&eacute;n compartimos la informaci&oacute;n recopilada?</h4>

<p>Usted presta su consentimiento inequ&iacute;voco para que PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo pueda compartir la Informaci&oacute;n Personal relevante de sus usuarios con proveedores para la gesti&oacute;n de sus reservas y/o solicitudes de compra, tales como aerol&iacute;neas, hoteles, empresas tur&iacute;sticas que prestan servicios en destino, compa&ntilde;&iacute;as rentadoras de veh&iacute;culos, mayoristas, y otros proveedores de productos y servicios que Usted contrate por nuestro Sitio Web y/o nuestra Aplicaci&oacute;n. Asimismo, Usted acepta compartir su Informaci&oacute;n Personal con proveedores que prestan servicios a PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo, incluyendo tarjetas de cr&eacute;dito, anal&iacute;tica comercial, servicio de atenci&oacute;n al cliente, marketing y prevenci&oacute;n de fraude.</p>

<p>PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo podr&iacute;a autorizar a proveedores de servicios a recopilar Informaci&oacute;n Personal en su nombre, incluyendo seg&uacute;n sea necesario para operar ciertos elementos de nuestro Sitio Web y/o nuestra Aplicaci&oacute;n o para facilitar la entrega de publicidad en l&iacute;nea adaptada a sus intereses. Adem&aacute;s, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo podr&aacute; compartir Informaci&oacute;n Personal con socios comerciales, con quienes conjuntamente podr&iacute;amos ofrecer productos o servicios. Dichos proveedores y socios comerciales est&aacute;n sujetos a estrictos contratos de confidencialidad que proh&iacute;ben la utilizaci&oacute;n o divulgaci&oacute;n no autorizada de la Informaci&oacute;n Personal a la cual tienen acceso. PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo podr&aacute; compartir tambi&eacute;n su Informaci&oacute;n Personal con la finalidad de cumplir la normativa aplicable y cooperar con las autoridades competentes.</p>

<p>Finalmente, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo podr&aacute; tambi&eacute;n compartir su Informaci&oacute;n Personal con las empresas pertenecientes al grupo PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo.com. En el caso de transferencia de datos personales, se informa que el receptor de los datos personales asumir&aacute; las mismas obligaciones que corresponden al responsable que transfiere los datos personales.</p>

<p>Tenga en cuenta que en caso de que Usted facilite de manera libre y voluntaria, por propia iniciativa, informaci&oacute;n directamente a los prestadores de servicios (sean o no prestadores de servicios en PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo), tales prestadores recopilar&aacute;n, tratar&aacute;n y conservar&aacute;n esta informaci&oacute;n conforme a sus propias pol&iacute;ticas de privacidad. PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo no se hace responsable por el uso indebido de la Informaci&oacute;n Personal que pudieran hacer terceras compa&ntilde;&iacute;as fuera del sitio web o de la aplicaci&oacute;n.</p>

<h4>4. &iquest;D&oacute;nde almacenamos y c&oacute;mo protegemos la informaci&oacute;n recopilada?</h4>

<p>Toda Informaci&oacute;n Personal es recolectada y almacenada en servidores ubicados f&iacute;sicamente en los Estados Unidos. PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo puede reubicar estos servidores en cualquier otro pa&iacute;s, en el futuro, y puede almacenar Informaci&oacute;n Personal en los Estados Unidos o en otros pa&iacute;ses, con fines de respaldo o back up. Usted presta su consentimiento inequ&iacute;voco para que PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo pueda transferir sus datos con destino a cualquier pa&iacute;s del mundo. En cualquier caso, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo se compromete a garantizar por que se cumpla con est&aacute;ndares legalmente exigibles para la protecci&oacute;n y resguardo de sus Informaci&oacute;n Personal, mediante la firma de acuerdos o convenios cuyo objeto sea la privacidad de sus datos personales.</p>

<p>La Informaci&oacute;n Personal ser&aacute; tratada con el grado de protecci&oacute;n legalmente exigible para garantizar la seguridad de la misma y evitar su alteraci&oacute;n, p&eacute;rdida, tratamiento o acceso no autorizado.</p>

<p>PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo resguarda su Informaci&oacute;n Personal de acuerdo a est&aacute;ndares y procedimientos de seguridad y confidencialidad impuestas en Argentina por la Disposici&oacute;n de la Direcci&oacute;n Nacional de Protecci&oacute;n de Datos Personales N&ordm; 11/2006 y por los arts. 9 y 10 de la Ley de LPDP; y normas conexas. PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo no transmite, divulga o proporciona la Informaci&oacute;n Personal recopilada a terceros diferentes del titular de dicha Informaci&oacute;n Personal y/o aquellos terceros descriptos en la presente Pol&iacute;tica. En este sentido, este Sitio Web y la Aplicaci&oacute;n toman los recaudos para proteger la Informaci&oacute;n Personal de los usuarios.</p>

<h4>5. &iquest;C&oacute;mo se puede acceder, eliminar y/o actualizar la informaci&oacute;n recopilada?</h4>

<h4>5.1.&nbsp;Pol&iacute;tica de renuncia (opt out)</h4>

<p>Cuando Usted realiza transacciones o se registra como usuario en PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo, podr&aacute; optar por recibir circulares promocionales, mensajes o alertas de correo electr&oacute;nico sobre ofertas. Tambi&eacute;n se le dar&aacute; en cada mensaje de correo electr&oacute;nico que le enviemos la oportunidad de cancelar su suscripci&oacute;n. Usted podr&aacute; modificar sus elecciones en cualquier momento, siguiendo cualquiera de las alternativas que se explican m&aacute;s adelante&nbsp;(&ldquo;Derechos de Acceso, cancelaci&oacute;n, rectificaci&oacute;n y oposici&oacute;n de la Informaci&oacute;n Personal&rdquo;).</p>

<p>Tenga en cuenta que el ejercicio del derecho de opt out refiere a circulares promocionales, mensajes o alertas de correo electr&oacute;nico sobre ofertas. Por lo tanto, Usted continuar&aacute; recibiendo comunicaciones referidas al estado de sus reservas activas. Incluso en determinados casos, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo deber&aacute; mantener en sus archivos cierta Informaci&oacute;n Personal, con la finalidad de resolver disputas o reclamaciones, detectar problemas o incidencias y solucionarlos, y dar cumplimiento a lo dispuesto en los T&eacute;rminos y Condiciones Generales por un per&iacute;odo de tiempo determinado por la ley. En cualquier caso, la Informaci&oacute;n Personal de un usuario no ser&aacute; inmediatamente retirada de nuestros archivos por motivos legales y t&eacute;cnicos, incluyendo sistemas de soportes de seguridad. Por tanto, si bien PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo se compromete a realizar sus mejores esfuerzos teniendo en cuenta el estado de la t&eacute;cnica, podr&aacute; suceder que no toda la Informaci&oacute;n Personal sea definitivamente eliminada. Le hacemos saber que para hacer cualquier modificaci&oacute;n en la informaci&oacute;n oportunamente suministrada, Usted puede ingresar en la secci&oacute;n &ldquo;Mi cuenta&rdquo; &gt; &ldquo;Mi Perfil&rdquo;.</p>

<h4>5.2.&nbsp;Derechos de acceso, cancelaci&oacute;n, rectificaci&oacute;n y oposici&oacute;n de la Informaci&oacute;n Personal</h4>

<p>Los usuarios, titulares de la Informaci&oacute;n Personal tienen -en Argentina- reconocidos y podr&aacute;n ejercitar los derechos de acceder, cancelar y actualizar su Informaci&oacute;n Personal, as&iacute; como a oponerse al tratamiento de la misma y a ser informado de las cesiones llevadas a cabo, en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un inter&eacute;s leg&iacute;timo al efecto conforme lo establecido en el art&iacute;culo 14 inciso 3 de la LPDP en Argentina.&nbsp;La Agencia de Acceso a la Informaci&oacute;n P&uacute;blica tiene la atribuci&oacute;n de atender las denuncias y reclamos que se interpongan con relaci&oacute;n al incumplimiento de las normas sobre protecci&oacute;n de datos personales.</p>

<p>Los usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de la Informaci&oacute;n Personal facilitada, y se comprometen a mantenerla debidamente actualizada.</p>

<h4>5.3. &iquest;C&oacute;mo ejercer los derechos descritos anteriormente?</h4>

<p>Usted puede ejercerlo por cualquiera de las opciones a continuaci&oacute;n:</p>

<ul>
	<li>Con la presentaci&oacute;n de la solicitud respectiva en Maipu 464, Ciudad Aut&oacute;noma de Buenos Aires, Rep&uacute;blica Argentina, o con el env&iacute;o de la misma por correo electr&oacute;nico a la direcci&oacute;n datospersonales@PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo.com . En su solicitud deber&aacute; indicar su nombre completo y, en su caso, documento que acredite la representaci&oacute;n legal del titular, adjuntar copia simple de su documento nacional de identidad, indicaci&oacute;n de su correo electr&oacute;nico y direcci&oacute;n postal que designe para notificaciones y alg&uacute;n n&uacute;mero telef&oacute;nico de contacto; y descripci&oacute;n clara y precisa de los datos personales respecto de los que busca ejercer el derecho de acceso, rectificaci&oacute;n y cancelaci&oacute;n y documentos que sustenten el cambio. Una vez cumplido con los recaudos antes detallados, y siempre que correspondiera hacer lugar al pedido, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo le comunicar&aacute; a Usted si ha procedido a dar lugar a la misma o si rechaza el pedido. PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo cuenta con 10 (diez) d&iacute;as h&aacute;biles desde la recepci&oacute;n de la solicitud para enviar una respuesta en caso de tratarse de una solicitud de acceso, y 5 (cinco) d&iacute;as h&aacute;biles si Usted solicitara la rectificaci&oacute;n, actualizaci&oacute;n o supresi&oacute;n de su Informaci&oacute;n Personal.</li>
	<li>Adicionalmente, Usted puede acceder, actualizar y corregir su informaci&oacute;n de registro en cualquier tiempo accediendo a &ldquo;Mi Perfil&rdquo; en &ldquo;Mi Cuenta&rdquo; dentro del Sitio Web de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo.</li>
	<li>Accediendo a &ldquo;Emails y Alertas&rdquo; en &ldquo;Mi Cuenta&rdquo;, Usted puede elegir qu&eacute; mails desea recibir.</li>
</ul>

<h4>6. &iquest;C&oacute;mo interact&uacute;a PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo con sus redes sociales?</h4>

<p>Al registrarse en PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo le requiere elegir una identificaci&oacute;n de usuario y una contrase&ntilde;a (es decir, acceder a una cuenta personal dentro del Sitio Web). Asimismo, PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo le permite acceder a &ldquo;Mi Cuenta&rdquo; a trav&eacute;s de su cuenta de Facebook, de Google+ u otras redes sociales (en adelante las &ldquo;Redes Sociales&rdquo; o &ldquo;Red Social&rdquo;) que en el futuro se compatibilicen con el acceso a &ldquo;Mi Cuenta&rdquo;. Como parte de la funcionalidad del Sitio Web y para brindar una mejor experiencia personalizada, puede enlazar su cuenta con Redes Sociales de dos maneras:</p>

<ul>
	<li>proporcionando la informaci&oacute;n de inicio de sesi&oacute;n de su cuenta de Red Social a trav&eacute;s del Sitio Web y/o la Aplicaci&oacute;n; o</li>
	<li>permitiendo a PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo acceder a su cuenta de Redes Sociales, seg&uacute;n se permita en los t&eacute;rminos y condiciones aplicables que rigen el uso que Usted hace de cada Red Social. Usted declara que tiene derecho a revelar la informaci&oacute;n de inicio de sesi&oacute;n de su Red Social a PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo y/o conceder el acceso a PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo a su cuenta, sin que ello suponga un incumplimiento por su parte de ninguno de los t&eacute;rminos y condiciones que rigen el uso que Usted hace de la correspondiente Red Social y sin que PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo quede obligado a pagar ninguna tasa o a respetar ninguna limitaci&oacute;n de uso impuesta por los proveedores externos de servicios de la Red Social. Por el hecho de conceder a PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo acceso a cualquier Red Social, Usted entiende que PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo acceder&aacute;, har&aacute; disponible y almacenar&aacute; (si procede) todos los contenidos que Usted haya proporcionado y almacenado en su Red Social para que est&eacute; disponible en el Sitio Web y/o la Aplicaci&oacute;n, y por medio de ellos, a trav&eacute;s de su cuenta de Red Social y de la p&aacute;gina de perfil de dicha cuenta. A menos que se especifique lo contrario en estos T&eacute;rminos, todos los contenidos de Redes Sociales, si los hubiera, se considerar&aacute;n contenidos de usuarios a todos los efectos de los presentes T&eacute;rminos.</li>
</ul>

<p>En funci&oacute;n de las Redes Sociales que elija y con sujeci&oacute;n a la configuraci&oacute;n de privacidad que haya establecido en dichas cuentas, la informaci&oacute;n de identificaci&oacute;n personal que publique en sus Redes Sociales estar&aacute; disponible en su cuenta de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo y a trav&eacute;s de ella en el Sitio Web y/o la Aplicaci&oacute;n. Debe tener en cuenta que si una Red Social deja de estar disponible, o si el proveedor externo de servicios bloquea el acceso de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo a dicha Red Social, los contenidos de las Redes Sociales dejar&aacute;n de estar disponibles en el Sitio Web y/o la Aplicaci&oacute;n o a trav&eacute;s de ellos.</p>

<p>TENGA EN CUENTA QUE SU RELACI&Oacute;N CON LOS PROVEEDORES EXTERNOS DE SERVICIOS ASOCIADOS SE RIGE EXCLUSIVAMENTE POR LOS ACUERDOS QUE TENGA SUSCRITOS CON DICHOS PROVEEDORES EXTERNOS DE SERVICIOS. PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo no dedicar&aacute; ning&uacute;n esfuerzo a revisar los contenidos de cuentas de Redes Sociales por ning&uacute;n motivo, incluyendo entre otros, la precisi&oacute;n, legalidad o no infracci&oacute;n, y no asume ninguna responsabilidad por ninguno de los contenidos de Redes Sociales. De esa forma, es m&aacute;s f&aacute;cil personalizar nuestra p&aacute;gina web seg&uacute;n tus preferencias, conectarte con tus amigos para hablar de destinos y analizar y mejorar nuestros servicios de viajes.</p>

<p>Si Usted pierde el control de su cuenta o contrase&ntilde;a de acceso a las Redes Sociales puede perder el control de su Informaci&oacute;n Personal y podr&iacute;a estar sujeto a transacciones legalmente v&aacute;lidas llevadas a cabo en su nombre. Por lo tanto, si por cualquier raz&oacute;n su contrase&ntilde;a llega a estar comprometida, Usted debe de inmediato: (i) cambiarla, modificando su informaci&oacute;n de registro que fue entregada a este Sitio Web, y (ii) contactarnos por cualquier canal que ofrezca PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo.</p>

<p>Este Sitio Web puede contener v&iacute;nculos (links) a otros sitios web que tienen sus propias pol&iacute;ticas de privacidad y confidencialidad. Por ello le recomendamos que si Usted visita esos otros sitios web, revise cuidadosamente sus pr&aacute;cticas y pol&iacute;ticas de confidencialidad, toda vez que esta Pol&iacute;tica de Privacidad no cubre las pr&aacute;cticas o pol&iacute;ticas de terceros, incluyendo aquellos que pueden revelar y/o compartir informaci&oacute;n con PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo.</p>

<p>Esta Pol&iacute;tica fue modificada y actualizada el 25 de mayo de 2018. PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo podr&aacute; realizar modificaciones a esta Pol&iacute;tica de Privacidad en el futuro. Cualquier modificaci&oacute;n a la manera como PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo utiliza la Informaci&oacute;n Personal ser&aacute; reflejada en versiones futuras de esta &ldquo;Pol&iacute;tica de Privacidad,&rdquo; y ser&aacute;n publicadas en esta p&aacute;gina, por lo que se aconseja revisar peri&oacute;dicamente la Pol&iacute;tica de Privacidad.</p>

<p>Si tiene preguntas sobre esta Pol&iacute;tica de Privacidad, las pr&aacute;cticas de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo o sus transacciones en la p&aacute;gina de PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo&nbsp;www.PYTHAGORAS SOFTWARE SOLUTIONS SRL / Bazar de Turismo.com.ar, nos puede contactar en &ldquo;Mi Cuenta&rdquo; O &ldquo;Mi Reserva&rdquo;.</p>

     
    </div>
  </div>
</div>