<div class="slider slider-partners-somos">
  <div class="content-slider slider-partners-somos container">
    <div class="content-slider-info">
      <h2>partners</h2>
      <p>
        gracias a nuestra integración XML ofrecemos una ampila y completa red de proveedores de productos y prestadores de servicios turísticos
      </p>
      <button routerLink="/contacto" class="button btn-wihte">contáctanos</button><button routerLink="/pricing" class="button btn-blue ml-4">nuestras soluciones</button>
    </div>
    <div class="content-slider-img">
      <img class="draw-images" src="/assets/images/ilustracion_partners.svg" />
    </div>
   
  </div>
</div> 
<div class="container pt-4">
  <div class="row">
    <div class="col-sm-12 pt-5">
      

      <div class="container">
        <div class="title">
          <h2></h2>
        </div>
      </div> 
     

      <div class="container">
        <div class="title">
  
          <div class="row partners">

            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/abreu.png" alt="Abreu" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/amadeus.png" alt="Amadeus" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/american-airlines.png" alt="american-airlines" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/assist-card.png" alt="assist-card" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/booking.png" alt="booking" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/british-airways.png" alt="british-airways" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/carrani-tours.png" alt="carrani-tours" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/copa-airlines.png" alt="copa-airlines" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/dingus.png" alt="dingus" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/europamundo.png" alt="europamundo" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/hahn-air.png" alt="hahn-air" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/hotelbeds.png" alt="hotelbeds" />
            </div> 
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/hoteldo.png" alt="hoteldo" />
            </div>

            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/hyperguest.png" alt="hyperguest" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/iberia.png" alt="iberia" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/ineedtours.png" alt="ineedtours" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/jetsmart.png" alt="jetsmart" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/juniper.png" alt="juniper" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/lufthansa.png" alt="lufthansa" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/methabook.png" alt="methabook" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/miki_travel.png" alt="miki_travel" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/Protect_group.png" alt="Protect_group" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/restel.png" alt="restel" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/room-cloud.png" alt="room-cloud" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/sabre.png" alt="sabre" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/special-tours.png" alt="special-tours" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/surland.png" alt="surland" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/swiss.png" alt="swiss" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/tbo.png" alt="tbo" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/travelport.png" alt="travelport" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/universal-assistance.png" alt="universal assistance" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/w2m.png" alt="W2M" />
            </div>
            <div class="col-md-3"> 
              <img src="/assets/images/partners-nuevo/welcomebeds.png" alt="Welcomebeds" />
            </div>
          </div>     
        </div>  
      </div> 
      <div class="section-home-newsletter container mb-6">
        <div class="title pt-5 mt-5 mb-5">
          <h2>contáctate con nosotros por más información</h2>
        </div>  
        <div class="row">
          <div class="col-md-4"> 
            <form (ngSubmit)="onSubmit()" #formNews="ngForm" [formGroup]="news">
              <input class="input" type="text" formControlName="email" name="email"  [ngClass]="{'aviso-input': faltaEmail > 0 }" placeholder="ingresa tu e-mail">
              <button class="button btn-blue ml-4 suscribite">{{button_text}}</button>   
            </form>
             <div [ngClass]="{'aviso-text': faltanCampos > 0 }" class="col-sm-6 pt-4 pl-3 position-absolute-25bt hidden">
                 debe ingresar su e-mail
             </div>
           </div>
          <div class="col-md-4">
            <button routerLink="/pricing" class="button btn-blue ml-4 full-width mb-2">ver soluciones</button>   
          </div>
          <div class="col-md-4">
            <button routerLink="/contacto" class="button btn-blue ml-4 full-width">contáctanos</button>   
          </div>
        </div> 
      </div>

      <p-dialog header="Titulo" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
        Muchas gracias por su interés en nuestra compañía, lo mantendremos informado de todas las novedades.
        <p-footer>
          <button type="button" pButton icon="pi pi-check" (click)="displayBasic=false" label="Yes">OK</button>
        </p-footer>
      </p-dialog>
     

     
     
    </div>
  </div>
</div>