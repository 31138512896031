<div class="container pt-4 mb-5">
  <div class="row">
    <div class="col-sm-12 pt-5">
      <div _ngcontent-bnr-c3="" class="title mb-5"><h2 _ngcontent-bnr-c3="">política de cookies</h2></div>
      
      <p>Las presentes Condiciones de Uso regulan el acceso, navegación y uso del presente sitio web (en adelante, el “Sitio Web”).</p>
      
      <h4>1.- Datos del titular</h4>
      <p>En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, le facilitamos los datos del titular del presente Sitio Web:</p>
      <p>1) PYTHAGORAS SOFTWARE SOLUTIONS SRL, (en adelante, “Pythagoras”) con CUIT 30710763646 y domicilio social </p>
      <p>Pythagoras prestará servicios de forma directa, y en particular los relacionados con su actividad, de agencia de viajes, como por ejemplo en relación con viajes, traslados, actividades, excursiones y visitas guiadas.</p>
      
      <h4>2.- Usuarios</h4>
      <p>El acceso y/o uso del Sitio Web le atribuye la condición de Usuario, y acepta, desde dicho acceso y/o uso, las presentes Condiciones de Uso.</p>
      
      <h4>3.- Uso del sitio web</h4>
      <p>El Sitio Web puede proporcionar acceso a multitud de textos, gráficos, dibujos, diseños, códigos, software, fotografías, música, vídeos, sonidos, bases de datos, imágenes, expresiones e informaciones (en adelante, "Contenidos") pertenecientes a Pythagoras o a terceros a los que el Usuario puede tener acceso.</p>
      <p>El Usuario asume la responsabilidad del uso del Sitio Web. Dicha responsabilidad se extiende al registro que, en su caso, sea necesario para acceder a Contenidos o servicios que preste el Sitio Web.</p>
      <p>El Usuario se compromete a hacer un uso lícito, diligente y adecuado de los Contenidos y servicios ofrecidos a través del Sitio Web y todo ello bajo los principios de la buena fe y respetando en todo momento a la legalidad vigente y a las presentes Condiciones de Uso.</p>
      <p>En particular, con carácter enunciativo pero no limitativo, el Usuario del Sitio Web:</p>
      <ul>
        <li>No utilizará o reutilizará material ilegal, ofensivo, abusivo, indecente, difamatorio, obsceno o amenazante de ningún tipo, o que suponga una violación de derechos de propiedad intelectual, marcas registradas, confidencialidad, privacidad o cualquier otro derecho, o sea de otro modo injurioso o censurable para terceras partes, o cuyo contenido contenga virus informáticos, propaganda política, contenido publicitario, correos en cadena, envío masivo de correos o cualquier otro tipo de "spam" y, en general, cualquier tipo de molestia o inconveniente innecesario.</li>
        <li>No provocará ni maliciosa ni intencionadamente daños o perjuicios que puedan menoscabar, alterar el propio Sitio Web, ni tampoco introducirá, ni difundirá virus informáticos que puedan producir alteraciones no autorizadas de los Contenidos o sistemas integrantes del Sitio Web.</li>
        <li>No utilizará el Sitio Web con fines fraudulentos, o relacionados con delitos penales o actividades ilícitas de ningún tipo.</li>
        <li>No reproducirá, copiará, distribuirá, transformará o modificará la información y los Contenidos alojados en el Sitio Web, a menos que cuente con la autorización del titular de los correspondientes derechos.</li>
      </ul>
      <p>Pythagoras se reserva el derecho a denegar el acceso al Sitio Web a Usuarios que hagan un mal uso de los Contenidos y/o incumplan cualquiera de las condiciones que aparecen en el presente documento.</p>
      
      <h4>4.- Propiedad intelectual</h4>
      <p>Todos los Contenidos que se muestran en el Sitio Web y en especial diseños, textos, gráficos, logos, iconos, botones, software, nombres comerciales, marcas, dibujos industriales o cualesquiera otros signos susceptibles de utilización industrial y comercial están sujetos a derechos de propiedad intelectual e industrial de Pythagoras o de terceros titulares de los mismos que han autorizado debidamente su inclusión en el Sitio Web, y es a ellos a quien corresponde el ejercicio exclusivo de los derechos de explotación de los mismos.</p>
      <p>Pythagorasno concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otra propiedad o derecho relacionado con el Sitio Web, los servicios o los Contenidos del mismo.</p>  
      <p>Por ello y en virtud de lo dispuesto en el Real Decreto Legislativo 1/1996, de 12 de Abril, por el que se aprueba el Texto Refundido de la Ley de Propiedad Intelectual, así como en la Ley 17/2001, de 7 de diciembre, de Marcas y la legislación complementaria en materia de propiedad intelectual e industrial, queda prohibida la reproducción, transmisión, adaptación, traducción, distribución, comunicación pública, incluida su modalidad de puesta a disposición, o cualquier otra explotación y/o modificación, total o parcial, sin previa autorización expresa de Pythagoras de cualquier Contenido incluido en el Sitio Web.</p>
      <p>Los Usuarios se comprometen a respetar los derechos de propiedad del Sitio Web y los derechos de propiedad intelectual e industrial sobre el mismo, sean de Pythagoras o de terceros.</p>
      <p>Asimismo, Pythagorasrechaza todo tipo de responsabilidad acerca de la explotación o la difusión de las imágenes y/o Contenidos insertados en el Sitio Web.</p>
      
      <h4>5.- Responsabilidad y garantías</h4>
      <p>Pythagorasdeclara que ha adoptado las medidas necesarias que, dentro de sus posibilidades y el estado de la tecnología, permitan el correcto funcionamiento de su sitio Web así como la ausencia de virus y componentes dañinos.</p>
      <p>Sin embargo, Pythagoras no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran derivarse del acceso o uso de los Contenidos o del Sitio Web. Entre otras, y a título enunciativo y no limitativo, Pythagoras no se responsabiliza de las siguientes circunstancias:</p>
      <ul>
        <li>La falta de disponibilidad, mantenimiento y efectivo funcionamiento del Sitio Web y/o de sus servicios o Contenidos, excluyéndose, en la máxima medida permitida por la legislación vigente, cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la falta de disponibilidad o de continuidad del funcionamiento del Sitio Web y de los Contenidos habilitados en el mismo.</li>
        <li>Los errores que pudieran existir en los Contenidos ni la corrección de cualquier defecto que pudiera ocurrir.</li>
        <li>La falta de utilidad del Sitio Web o los Contenidos.</li>
        <li>La existencia de virus, programas maliciosos o lesivos en los Contenidos.</li>
        <li>El mal servicio o cualquier problema surgido con las empresas proveedoras de los servicios.</li>
        <li>La recepción, obtención, almacenamiento, difusión o transmisión, por parte de los Usuarios, de los Contenidos.</li>
        <li>El uso ilícito, negligente, fraudulento, contrario a los términos de las presentes Condiciones de Uso, o a la buena fe, del Sitio Web o sus Contenidos, por parte de los Usuarios.</li>
      </ul>
      <p>Pythagoras podrá suspender temporalmente y sin previo aviso, la accesibilidad al Sitio Web con motivo de operaciones de mantenimiento, reparación, actualización o mejora. No obstante, siempre que las circunstancias lo permitan, Pythagoras comunicará al Usuario, con antelación suficiente, la fecha prevista para la suspensión de los servicios.</p>
      
      <h4>6.- Links a páginas de terceros</h4>
      <p>El Sitio Web puede contener links o hipervínculos a páginas web titularidad de terceros.</p>
      <p>Pythagoras no asume ninguna responsabilidad por el contenido, informaciones o servicios que pudieran aparecer en dichos sitios web, sobre los que Pythagoras no tiene ningún tipo de control, por lo que el Usuario accede bajo su exclusiva responsabilidad al contenido y en las condiciones de uso que rijan en los mismos.</p>
      <p>En este sentido, les recomendamos que lean las políticas de privacidad y condiciones de uso de estos sitios web propiedad de terceros.</p>
      
      <h4>7.- Comentarios y opiniones</h4>
      <p>En caso de que Pythagoras permitiera a los Usuarios publicar sus opiniones y/o comentarios en el Sitio Web, éstos no deberán contener expresiones que no sean aptas para todos los públicos, incluyendo por tanto, textos indecorosos o que no se consideren apropiados o que hieran la sensibilidad general.</p>
      <p>Tampoco se permitirán comentarios y/o opiniones que vayan en contra de un particular y que vulneren los principios del derecho al honor, a la intimidad personal y familiar, a la propia imagen y a la dignidad de la persona. Se prohíbe cualquier tipo de comentario discriminatorio ya sea por motivos de raza, sexo, religión, opinión, nacionalidad, discapacidad o cualquier otra circunstancia personal o social.</p>
      <p>Asimismo, no están permitidas aquellas publicaciones que sean intimidantes, amenazantes degradantes o que de cualquier manera promuevan la violencia contra una persona o un colectivo determinado.</p>
      <p>Tampoco podrá utilizarse el Sitio Web como medio para realizar publicidad, promoción de negocios, marcas o elementos personales.</p>
      <p>En cualquier caso, Pythagoras no será responsable de las opiniones y/o comentarios vertidos en el Sitio Web, siendo en todo caso responsabilidad de los autores de las mismas.</p>
      
      <h4>8.- Duración y modificación</h4>
      <p>Las presentes Condiciones de Uso estarán vigentes hasta que sean modificadas, pudiendo Pythagoras efectuar estos cambios que serán comunicados al Usuario.</p>
      <p>Pythagoras podrá suprimir, añadir o cambiar tanto los Contenidos como los servicios que presta, así como también la forma en la que los mismos aparezcan localizados o presentados. Se entienden como vigentes, las condiciones que estén publicadas en el momento en el que el usuario acceda al Sitio Web de Pythagoras.</p>
      <p>El acceso y/o el uso del Sitio Web se entenderán como una aceptación por parte del usuario de las presentes Condiciones de Uso y, en su caso, los cambios efectuados en las mismas.</p>
      
      <h4>9.- Legislación y jurisdicción aplicables</h4>
      <p>Los términos, condiciones y demás documentos de Pythagoras se regirán por la legislación vigente.</p>
      <p>En caso de disputa, las partes se someten a los Juzgados y Tribunales del domicilio del Usuario en caso de ser éste una persona física, y a los Juzgados y Tribunales en caso de ser persona jurídica.</p>
    </div>
  </div>
</div>