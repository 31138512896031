import {Component} from '@angular/core';


/**
 * @title Dialog elements
 */
@Component({
  selector: 'dialog-elements',
  templateUrl: 'dialog-elements.component.html',
})
export class DialogElements {

  displayBasic: boolean;
  

  showBasicDialog() {
    this.displayBasic = true;
  }

  constructor() {
    this.showBasicDialog()
  }

}

