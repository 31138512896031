<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37071.59596142765!2d-56.07928765439922!3d-34.78839282215882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a029011183daff%3A0x49bf1af73a82eb55!2sBiotec%20Plaza%2C%20Henry%20Ford%2C%2012200%20Montevideo%2C%20Departamento%20de%20Montevideo%2C%20Uruguay!5e0!3m2!1ses-419!2sar!4v1642077139690!5m2!1ses-419!2sar" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
<div class="container pt-4 mb-4">
  <div class="row container">
    <div class="col-sm-12 pt-5">
      
      <div class="title mb-5">
        <h2>agencias</h2>
      </div>

      <div class="row pb-4 data-text">
        <div class="col-md-4 text-align-center">
          
        </div>
        <div class="col-md-4 text-align-center">
          <br>
          Sede Montevideo<br>
          Ruta 8, Km 17500 <br>
          Montevideo, Zonamerica - Edificio Biotec Local 005

        </div>
        <div class="col-md-4 text-align-center">
        
        </div>
      </div>  
      <form class="formulario-contacto" #formContact="ngForm" [formGroup]="contacto" (ngSubmit)="saveData()" >
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <input class="input" type="text" formControlName="nombre" placeholder="nombre y apellido*" [ngClass]="{'aviso-input': faltaNombre > 0 }" required>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <input class="input" type="text" formControlName="email" placeholder="e-mail*" [ngClass]="{'aviso-input': faltaEmail > 0 }" required> 
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <input class="input" type="text" formControlName="tel" placeholder="teléfono*" [ngClass]="{'aviso-input': faltaTel > 0 }" required>
          </div>

          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-6 pl-4"  required>
            <div class="opcion-idoneo width-100" [ngClass]="{'aviso-text': faltaIdoneo > 0 }">¿Es idoneo?</div>

            <div class="opcion-idoneo">
              <input type="radio" id="opcion-si" formControlName="idoneo"  name="idoneo" value="si" >
              <label for="opcion-si"> Si</label>
            </div>
            
            <div class="opcion-idoneo">
              <input type="radio" id="opcion-no" formControlName="idoneo" name="idoneo" value="no">
              <label for="opcion-no"> No</label>
            </div>
            
  
            
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-5">
 
              <select id="paises" formControlName="pais"  class="input" [ngClass]="{'aviso-input': faltaPais > 0 }" >
                  <option *ngFor="let c of paises" [ngValue]="c">{{ c }}</option>
              </select>
  
              
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-5" >

            <select id="empleados" formControlName="empleados"  class="input" [ngClass]="{'aviso-input': faltaEmpleados > 0 }" >
                <option *ngFor="let c of cant_empleados" [ngValue]="c">{{ c }}</option>
            </select>
          </div>

          <div class="col-12 seccion-2 mt-5">
            <textarea class="input textarea" formControlName="mensaje" [ngClass]="{'aviso-input': faltaMensaje > 0 }" rows="7">mensaje</textarea>
          </div>
          <div [ngClass]="{'aviso-text': faltanCampos > 0 }" class="col-sm-6 pb-4 pl-3">
            *Campos obligatorios
          </div>
          <div class="col-sm-6 text-align-right pt-1">
            <button class="button btn-blue ml-4">{{button_text}}</button>   
          </div>
         
        </div>

      </form>



      <div style="display:none;" class="clientes-opinan mt-5 pt-5 mb-5">
        <div class="title mb-5">
          <h2>nuestros clientes opinan sobre nuestros productos</h2>
        </div>

        <div class="row">
          <div class="col-md-4"> 
            <div class="content-opinion">
              <div class="icono-opinion"> 
              <img src="/assets/images/foto_cliente.jpg" />
              </div>          
              <div class="texto-opinion">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et tristique massa. Nunc pharetra blandit fringilla. Donec nec sapien justo. Pellentesque leo libero.
                </p>
                <h5>Long View</h5>
              </div>
            </div>
          </div>
          <div class="col-md-4"> 
            <div class="content-opinion">
              <div class="icono-opinion"> 
              <img src="/assets/images/foto_cliente.jpg" />
              </div>          
              <div class="texto-opinion">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et tristique massa. Nunc pharetra blandit fringilla. Donec nec sapien justo. Pellentesque leo libero.
                </p>
                <h5>Live Travel</h5>
              </div>
            </div>
          </div>
          <div class="col-md-4"> 
            <div class="content-opinion">
              <div class="icono-opinion"> 
              <img src="/assets/images/foto_cliente.jpg" />
              </div>          
              <div class="texto-opinion">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et tristique massa. Nunc pharetra blandit fringilla. Donec nec sapien justo. Pellentesque leo libero.
                </p>
                <h5>Destinos a medida</h5>
              </div>
            </div>
          </div>
        </div>	
      </div>
    </div>
  </div>
</div>


