import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { ServerService } from '../server.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-agencias',
  templateUrl: './agencias.component.html',
  styleUrls: ['./agencias.component.scss']
})
export class AgenciasComponent implements OnInit {

  showTab = 0;
  contacto: UntypedFormGroup;
  paises: string[] = ['Ubicación *', 'Argentina', 'Bolivia', 'Brasil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 'Ecuador', 'El Salvador', 'Guayana Francesa', 'Granada', 'Guatemala', 'Guayana', 'Haití', 'Honduras', 'Jamaica', 'México', 'Nicaragua', 'Paraguay', 'Panamá', 'Perú', 'Puerto Rico', 'República Dominicana', 'Surinam', 'Uruguay', 'Venezuela'];
  default_pais: string = 'Ubicación *';

  cant_empleados: string[] = ['Cantidad de empleados *', '1 a 3', '4 a 10', '11 a 20', '+ 20'];
  default_empleados: string = 'Cantidad de empleados *';


  button_text= "enviar";

  faltanCampos = 0;
  faltaNombre = 0;
  faltaEmail = 0;
  faltaTel = 0;
  faltaPais = 0;
  faltaIdoneo = 0;
  faltaEmpleados = 0;
  faltaMensaje = 0;

  constructor(public fb: UntypedFormBuilder,private server: ServerService, private metaService: Meta) {
    this.contacto = this.fb.group({
      nombre:         ['', []],
      email:          ['', []],
      tel:            ['', []],
      idoneo:         ['', []],
      pais:           ['', []],
      empleados:      ['', []],
      mensaje:        ['', []]
    });
    this.contacto.controls['pais'].setValue(this.default_pais, {onlySelf: true});
    this.contacto.controls['empleados'].setValue(this.default_empleados, {onlySelf: true});

  }

  saveData() {
    if(this.formularioValido() == false){
      return;
    };
    this.button_text = "enviando...";
    this.contacto.value.asunto = "Consulta Web Agencias: Bazar de Turismo";
    this.server.submitEmail(this.contacto.value).subscribe(val => console.log(val));
    this.button_text = "¡mensaje enviado!";
    this.contacto.reset();
  }


  ngOnInit() {

    this.metaService.addTags([
      {name: 'description', content: 'Nacimos para motorizar el cambio en la industria del turismo. Contáctate con nosotros por más información: Sede Montevideo. Ruta 8, Km 17500. Montevideo, Zonamerica - Edificio Biotec Local 005'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }

  
  formularioValido() {
   
    this.faltanCampos = 0;
    if(this.contacto.value.nombre == "" ){                                                                  this.faltaNombre++;         this.faltanCampos++; }else{       this.faltaNombre = 0;         }
    if(this.contacto.value.email == "" ){                                                                   this.faltaEmail++;          this.faltanCampos++; }else{       this.faltaEmail = 0;          }
    if(this.contacto.value.tel == "" ){                                                                     this.faltaTel++;            this.faltanCampos++; }else{       this.faltaTel = 0;            }
    if(this.contacto.value.idoneo == "" ){                                                                  this.faltaIdoneo++;         this.faltanCampos++; }else{       this.faltaIdoneo = 0;         }
    if(this.contacto.value.pais == "" || this.contacto.value.pais == this.default_pais ){                   this.faltaPais++;           this.faltanCampos++; }else{       this.faltaPais = 0;           }
    if(this.contacto.value.empleados == "" || this.contacto.value.empleados == this.default_empleados ){    this.faltaEmpleados++;      this.faltanCampos++; }else{       this.faltaEmpleados = 0;      }
    if(this.contacto.value.mensaje == "" ){                                                                 this.faltaMensaje++;        this.faltanCampos++; }else{       this.faltaMensaje = 0;        } 
    if(this.faltanCampos > 0){                                                                              return false;   }
      return true;
  }


  tabToggle(index){
    if(index == this.showTab){
      this.showTab = 0 ;
    }
    else{
      this.showTab =index;
    }
    
  }
}
